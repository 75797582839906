import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useStore } from '@/utils/hooks'
import Button from '@/components/Button'

const Receive = () => {
    const store = useStore()
    const { t } = useTranslation()

    const copy = () => {
        navigator.clipboard.writeText(store.user.wallet_address)
    }

    return (
        <>
            <h3>{t('Receive')}</h3>
            <Content>
                <img src={`https://chart.googleapis.com/chart?cht=qr&chl=${store.user.wallet_address}&chs=230x230`} alt={store.user.wallet_address} />
                <p>
                    {store.user.wallet_address}
                </p>
                <Button onClick={copy} variant={'primary'}>{t('Copy address')}</Button>
            </Content>
        </>
    )
}

const Content = styled.div`
    margin: 0 auto;
    max-width: 330px;
    text-align: center;
    
    p {
        display: inline-block;
        margin-top: 30px;
        font-size: 12px;
        background: #FFFFFF0D;
        border-radius: 10px;
        padding: 10px;
        overflow-wrap: break-word;
        text-align: center;
    }
    
    button {
        margin-top: 20px;
        width: 100%;
    }
`

export default Receive
