import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { GlobalStyle } from './style'
import Navbar from './components/Navbar'
import Login from './pages/Login'
import Marketplace from './pages/Marketplace'
import Inventory from './pages/Inventory'
import CheckUser from './pages/CheckUser'
import PagePreload from './pages/PagePreload'
import Helmet from './components/Helmet'
import styled from 'styled-components'
import VisionLinesBg from './assets/vision_lines_bg.png'
import Footer from './components/Footer'
import Wallets from './pages/Wallets'
import AddAffiliate from './pages/AddAffiliate'
import Affiliate from './pages/Affiliate'

const MainBg = styled.div`
   background-image: url(${VisionLinesBg});
   background-size: 100% 700px;
   background-repeat: no-repeat;
   background-position: center -50px;
   
  @media (max-width: 767px) {
    background-size: 100% 400px;
  }
`

function App() {
  return (
    <>
      <GlobalStyle />
      <Helmet />
      <Router>
        <MainBg>
          <PagePreload nav><Navbar /></PagePreload>
          <Routes>
            {['/', '*', '/marketplace'].map(route => (
              <Route
                key={route}
                path={route}
                element={<PagePreload><Marketplace /></PagePreload>}
              />
            ))}
            <Route path="/login" element={<PagePreload><Login /></PagePreload>} />
            <Route path="/inventory" element={<CheckUser><Inventory /></CheckUser>} />
            <Route path="/wallet" element={<CheckUser><Wallets /></CheckUser>} />
            <Route path="/add_affiliate" element={<PagePreload><AddAffiliate /></PagePreload>} />
            <Route path="/affiliate" element={<CheckUser><Affiliate /></CheckUser>} />
            <Route path="*" element={<CheckUser notFound />} />
          </Routes>
          <Footer />
        </MainBg>
      </Router>
    </>
  )
}

export default App
