import React from 'react'
import { ReactComponent as IconLock } from '@/assets/ico-lock.svg'
import { ReactComponent as IconCoins } from '@/assets/ico-coins.svg'
import { LockedBoxes } from './styled'
import { t } from '@/utils'

const LockedInfo = ({ topAmount }) => {
    return (
        <>
            <p>{t('25% paid immediately, 75% paid after the lock period (1 month after the purchase)')}</p>
            <LockedBoxes>
                <div>
                    <IconCoins />
                    <p>{t('Instant reward')}</p>
                    <b>{(topAmount * 0.25).toFixed(2)}</b>
                </div>
                <div>
                    <IconLock />
                    <p>{t('Post lockup period reward')}</p>
                    <b>{(topAmount * 0.75).toFixed(2)}</b>
                </div>
            </LockedBoxes>
        </>
    )
}

export default LockedInfo
