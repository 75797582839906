import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { formatAmount } from '@/utils/amount'
import { useStore } from '@/utils/hooks'
import { Asset, Content } from '../Spending/Balances'

const Balances = () => {
    const store = useStore()
    const { t } = useTranslation()

    const balances = useMemo(() => {
        const result = []

        store.settings.assets?.forEach(asset => {
            if (asset.active) {
                result.push({
                    ...asset, balance: store.wallet.assets[asset.ticker.toLowerCase()]
                })
            }
        })

        return result
    }, [store.settings, store?.user])

    return (
        <>
            <h3>{t('My wallet')}</h3>
            {balances.length > 0 ?
                <Content>
                    {balances.map(item => (
                        <div key={item.ticker}>
                            <Asset>
                                <img
                                    src={item.image}
                                    alt={item.ticker}
                                />
                                <strong>{item.ticker.toUpperCase()}</strong>
                            </Asset>
                            <span>{formatAmount(item.balance)}</span>
                        </div>
                    ))}
                </Content>
                :
                <>{t('Your balance is empty')}</>
            }
        </>
    )
}

export default observer(Balances)
