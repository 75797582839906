import SockPerformance from '@/assets/socks/performance.png'
import SockKarma from '@/assets/socks/karma.png'
import SockStamina from '@/assets/socks/stamina.png'
import Water02 from '@/assets/water/0.2L.png'
import Water1 from '@/assets/water/1L.png'
import Water2 from '@/assets/water/2L.png'

const SocksImages = {
    'performance': SockPerformance,
    'stamina': SockStamina,
    'karma': SockKarma,
}
const WaterImages = {
    200: Water02,
    1000: Water1,
    2000: Water2,
}

export { SocksImages, WaterImages }
