import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { handleLogin } from '@api'
import { useStore } from '@hooks'
import Pin from '@/components/PIN'
import { toastError, toastSuccess } from '@toast'
import { ReactComponent as IconKey } from '@/assets/key.svg'
import Verification from '../Verification'

const Wrap = styled.section`
    padding: 200px 20px;
    max-width: 600px;
    margin: 0 auto;
    h1 {
        &, & + p {
            text-align: center;
        }
        & + p {
            font-size: 24px;
            margin: 15px 0 60px;
        }
    }
    svg {
        display: block;
        margin: 0 auto 60px;
    }
    @media (max-width: 767px) {
        padding: 100px 20px;
        svg {
            margin: 0 auto 30px;
        }
        h1 + p {
            margin-bottom: 30px;
        }
    }
`

const Login = () => {
    const [step, setStep] = useState(1)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const { t } = useTranslation()
    const store = useStore()
    const navigate = useNavigate()

    useEffect(() => {
        if (store.user) {
            navigate(store.prevPath || '/')
            store.set('prevPath', '')
        }
    }, [store.user, navigate, store])

    const login = async (value) => {
        try {
            store.set('isLoading', true)

            const loginResult = await handleLogin({ username: email, password: value })
            if (loginResult.token) {
                localStorage.setItem('token', loginResult.token)
                await store.set('tokens', {
                    token: loginResult.token,
                    refreshToken: loginResult.refresh_token
                })
                await store.initUser()
                await store.getAppSettings()
                await store.getInventory()
                store.set('isLoading', false)

                toastSuccess('Login successful.')
                // redirect('/wallet')
                return
            }
            toastError(t(loginResult.message))
        } catch (err) {
            toastError(t('There was an error while logging in'))
        }
    }

    return step === 1 ?
        <Verification setStep={setStep} setEmail={setEmail} email={email} />
        : (
            <Wrap>
                <h1>{t('PIN code')}</h1>
                <p>{t('Enter the PIN code that was sent to')} {email}</p>
                <IconKey />
                <Pin
                    onChange={setPassword}
                    onComplete={login}
                />
            </Wrap>
        )
}

export default observer(Login)
