import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Logo from '../assets/logo.svg'
import { ReactComponent as Instagram } from '../assets/socialMediaIcons/Instagram.svg'
import { ReactComponent as LinkedIn } from '../assets/socialMediaIcons/LinkedIn.svg'
import { ReactComponent as TikTok } from '../assets/socialMediaIcons/TikTok.svg'
import { ReactComponent as Youtube } from '../assets/socialMediaIcons/YouTube.svg'
import { ReactComponent as Twitter } from '../assets/socialMediaIcons/Twitter.svg'
import { ReactComponent as Discord } from '../assets/socialMediaIcons/Discord.svg'
import { ReactComponent as Facebook } from '../assets/socialMediaIcons/Facebook.svg'
import { ReactComponent as Medium } from '../assets/socialMediaIcons/Medium.svg'
import { ReactComponent as Reddit } from '../assets/socialMediaIcons/Reddit.svg'
import { ReactComponent as Telegram } from '../assets/socialMediaIcons/TelegramIcon.svg'
import {ReactComponent as AppStore} from '../assets/app_store.svg'
import { ReactComponent as GooglePlay } from '../assets/google_play.svg'
import { ReactComponent as Apk } from '../assets/apk.svg'
import { SimpleLink, Flex, Container, Grid } from './'
import { t } from '@/utils'

const FooterWrap = styled.footer`
    padding: 100px 0;
    @media(max-width:1024px){
        padding: 40px 0;
    }
    & > div > div {
        flex-wrap: wrap;
    }
    & > div > div > div {
        p:first-of-type {
            color: var(--text);
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
            
        }
        p {
            color: #35A6B6;
            font-size: 16px;
            padding: 5px 0;
        }  
    }
    & > div > div > div:first-of-type {
        max-width: 400px;
        justify-self: center;
        justify-content: space-between;
        p {
            font-size: 16px;
            color: var(--text);
        }
        div { 
            justify-content: space-between;
            @media(max-width:1024px) {
                justify-content: space-around;
            }
            /* background-color: red; */
        }
        @media(max-width:1024px) {
           max-width: 100%;
           margin: 0 auto;
           margin-bottom: 20px;
           @media(max-width:476px) {
                margin-bottom: 0;
            }
            img {
            margin: 0 auto;
           }
           p {
            max-width: 70%;
            text-align: center;
            margin: 20px auto;
           }  
        }
    }
    & > div > div > div:nth-of-type(2){
        @media(max-width:1024px){
           text-align: center;
           padding: 0 5px;
           width: 50%;
           p:first-of-type {
            margin-bottom: 0px;
           }
        }
        @media(max-width:476px) {
            width: 50%;
            p {
                text-align: left;
                width: 70%;
                margin-left: auto;
            }
        }
    }
    & > div > div > div:nth-of-type(3){
        /* background-color: red; */
        @media(max-width:1024px){
            padding: 0 5px;
            text-align: center;
            width: 50%;
            p:first-of-type {
            margin-bottom: 0px;
           }
        }
        @media(max-width:476px) {
            width: 50%;
            margin-bottom: 70px;
            p {
                text-align: left;
                width: 90%;
                margin-left: auto;
            }
        } 
    }
    & > div > div > div:nth-of-type(4) {
        max-width: 250px;
        padding: 0 5px;
        @media(max-width: 1024px){
            text-align: center;
            margin: 0 auto;
            margin-top: 10px;
            max-width: 100%;
            & > div:first-of-type {
               a  { 
                    margin: 0 auto;
                }
                a:last-of-type {
                    padding-right: 0;
                }
            }
            p:first-of-type {
            margin-bottom: 5px;
           }
        }
        & > div:nth-of-type(2) {
            position: relative;
            justify-content: space-between;
           a {
            color: #35A6B6;
            font-size: 16px;
           } 
           
        }
        & > p:last-of-type {
            margin-top: 20px;
            color: var(--text);
            font-size: 16px;
        }
        
    }
`

const StoresWrap = styled(Flex)`
    a {
        max-width: 50%;
        svg {
            max-width: 100%;
            max-height: 50px;
        }
        & + a {
            margin-left: 10px;
        }
    }
`

const SocialIconsWrap = styled(Grid)`
    margin-bottom: 20px;    
    a {
        width: 100%;
        svg {
            width: 100%;
            height: auto;
        }
    }
    @media (max-width: 1024px) {
        margin-top: 20px;
    }
`

const socialMediaLinks = [
    {
        img: <Twitter />,
        alt: 'TipTop Twitter',
        href: 'https://twitter.com/Tiptop_Official',
    },
    {
        img: <Facebook />,
        alt: 'TipTop Facebook',
        href: 'https://www.facebook.com/TipTopStep',
    },
    {
        img: <LinkedIn />,
        alt: 'TipTop LinkedIn',
        href: 'https://www.linkedin.com/company/tip-top-sneakers-pte-ltd/',
    },
    {
        img: <Discord />,
        alt: 'TipTop Discord',
        href: 'https://discord.gg/xkNHtY468N',
    },
    {
        img: <Instagram />,
        alt: 'TipTop Instagram',
        href: 'https://instagram.com/tiptop_io/'
    },
    {
        img: <TikTok />,
        alt: 'TipTop TikTok',
        href: 'https://tiktok.com/@tiptop_io'
    },
    {
        img: <Youtube />,
        alt: 'TipTop Youtube',
        href: 'https://youtube.com/@tiptop_io/featured'
    },
    {
        img: <Medium />,
        alt: 'TipTop Medium',
        href: 'https://medium.com/@tiptop_io'
    },
    {
        img: <Reddit />,
        alt: 'TipTop Reddit',
        href: 'https://www.reddit.com/r/TipTopOfficial/'
    },
    {
        img: <Telegram />,
        alt: 'TipTop Telegram',
        href: 'https://t.me/tiptop_io_official/'
    }
]


const TipTop = [
    {
        href: '/#vision',
        text: 'Our Story'
    },
    {
        href: '/#how-it-works',
        text: 'How it works'
    },
    {
        href: '/#roadmap',
        text: 'Roadmap',
    },
    {
        href: '/#tokenomics',
        text: 'Tokenomics',
    },
    {
        href: '/#team',
        text: 'Team',
    },
    {
        href: '/contact',
        text: 'Contact us'
    },
    {
        href: '/reps',
        text: 'Representatives'
    }
]

const Footer = () => {
    return (
        <FooterWrap>
            <Container width={1300} id='community'>
                <Flex justify={'space-between'}>
                    <Flex column>
                        <img width='200px' src={Logo} alt='TipTop' loading="lazy" />
                        <p>{t('TipTop is a move-to-earn app that gamifies fitness to promote active lifestyle and financial independence.')}</p>
                        <StoresWrap>
                            <SimpleLink href={'https://play.google.com/store/apps/details?id=com.tiptop.io'}>
                                <GooglePlay />
                            </SimpleLink>
                            <SimpleLink href="https://tiptop.io/app.apk">
                                <Apk />
                            </SimpleLink>
                            <SimpleLink href="https://apps.apple.com/app/tiptop-sneakers/id6444293849">
                                <AppStore />
                            </SimpleLink>
                            {/* <SimpleLink href={'https://apple.com'}><img width={'150px'} src={AppStore} alt='download TipTop' loading="lazy" /></SimpleLink> */}
                        </StoresWrap>
                    </Flex>
                    <div>
                        <p>TipTop</p>
                        {TipTop.map(({ href, text }) => <p key={text}><SimpleLink href={`https://tiptop.io${href}`}>{t(text)}</SimpleLink></p>)}
                    </div>
                    <div>
                        <div>
                            <p>{t('Application')}</p>
                            <p><SimpleLink href='https://tiptop.io/#how-to-play'>{t('How To Play')}</SimpleLink></p>
                            <p><SimpleLink href='https://tiptop.io/affiliate'>{t('Affiliate Program')}</SimpleLink></p>
                            <p><SimpleLink href='https://opensea.io/collection/tiptop-sneakers'>OpenSea</SimpleLink></p>
                            <p><SimpleLink href='https://tiptop.io/#faq'>{t('FAQ')}</SimpleLink></p>
                            <p><SimpleLink href="https://tiptop.io/TipTop_Whitepaper.pdf">{t('Whitepaper')}</SimpleLink></p>
                        </div>
                    </div>

                    <div>
                        <p>{t('Our Community')}</p>
                        <SocialIconsWrap col={4} colMSize={1024} colM={9} colXsSize={600} colXs={5}>
                            {socialMediaLinks.map(({ img, alt, href }) =>
                                <SimpleLink key={alt} href={href}>
                                    {img}
                                </SimpleLink>
                            )}
                        </SocialIconsWrap>
                        <Flex justify={window.innerWidth < 1024 ? 'center' : 'space-between'}>
                            <SimpleLink href={'https://tiptop.io/privacy'}>{('Privacy Policy')}</SimpleLink>
                            <SimpleLink href={'https://tiptop.io/privacy/#disclaimer'}>{t('Liability Disclaimer')}</SimpleLink>
                            {/* <SimpleLink> Terms of Use</SimpleLink> */}
                        </Flex>
                        <p>{t('Copyright')} © {new Date().getFullYear()} TipTop. {t('All rights reserved')}.</p>
                    </div>
                </Flex>
            </Container>
        </FooterWrap >
    )
}

export default Footer
