import { useState } from 'react'
import styled from 'styled-components'
import { StyledScrollbar } from './'
import { ReactComponent as IconArrow } from '../assets/arrow.svg'

const AccordionWrap = styled.div`
    & > div {
        &:first-child {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--text);
            padding: 10px 0;
            font-weight: 700;
            font-size: 16px;
            svg {
                transition: 0.5s all;
            }
            ${({ open }) => open ? `
                opacity: 0.5;
                svg {
                    transform: rotate(180deg);
                }
            ` : ''}
        }
        &:last-child {
            ${StyledScrollbar};
            transition: all 0.5s;
            ${({ open }) => open ? `
                max-height: 500px;
                filter: none;
                opacity: 1;
                padding: 10px 20px 20px;
            ` : `
                max-height: 0;
                overflow: hidden;
                filter: blur(4px);
                opacity: 0;
            `}
        }
    }
`

const Accordion = ({ title, content, children }) => {
    const [open, setOpen] = useState(false)
    return (
        <AccordionWrap open={open}>
            <div onClick={() => setOpen(!open)}>{title} <IconArrow /></div>
            <div>{content || children}</div>
        </AccordionWrap>
    )
}

export default Accordion