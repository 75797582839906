import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { formatAmount } from '../utils/amount'
import ReactTooltip from 'react-tooltip'

const Wrap = styled.div`
    min-height: 40px;
    min-width: 150px;
    margin-right: 10px;
    & > div, & {
        display: flex;
        align-items: center;
    }
    justify-content: space-between;
    background: #FFFFFF1A;
    border-radius: 10px;
    padding: 10px;
    & > div + div {
        margin-left: 15px;
    }
    img {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        margin-right: 5px;
    }
    transition: filter 1s;
    &:hover {
        background: var(--subbg);
        color: var(--special);
    }
`

const Balances = ({ balances }) => {
    return (
        <Wrap>
            {balances?.map(item => (
                <div key={item.ticker} data-tip={item.ticker} data-place={'bottom'}>
                    <img src={item.image} alt={item.ticker} />
                    <span>{formatAmount(item.balance)}</span>
                </div>
            ))}
            <ReactTooltip />
        </Wrap>
    )
}

export default observer(Balances)
