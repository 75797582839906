import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from '@/components/Button'
import { post } from '@/utils/api'
import { useStore } from '@/utils/hooks'
import { Footer } from '../../WalletModal'

const Confirm = ({ seed, onSuccess, setError }) => {
    const store = useStore()
    const { t } = useTranslation()

    const [phrases, setPhrases] = useState([])
    const [isButtonLoading, setIsButtonLoading] = useState(false)

    const getPhrases = (seed) => {
        const phrases = seed.split(' ')
        phrases.sort(() => Math.random() - 0.5)
        return phrases
    }

    const randomizedPhrases = useMemo(() => getPhrases(seed), [seed])

    const addPhrase = (phrase) => {
        let temp = [...phrases]
        temp.push(phrase)
        setPhrases(temp)
    }

    const removePhrase = (phrase) => {
        const index = phrases.indexOf(phrase)
        if (index > -1) {
            let temp = [...phrases]
            temp.splice(index, 1)
            setPhrases(temp)
        }
    }

    const importWallet = async () => {
        try {
            setIsButtonLoading(true)
            const result = await post({
                path: 'wallet/import',
                token: store.tokens.token,
                body: {
                    seed,
                }
            })

            store.updateWallet(result)

            onSuccess()
        } catch (error) {
            setError(error)
        } finally {
            setIsButtonLoading(false)
        }
    }

    const isValid = () => {
        if (randomizedPhrases.length !== phrases.length) {
            return null
        }

        return seed === phrases.join(' ')
    }

    return <>
        <p>
            {t('Select phrase keywords from the list to enter the generated phrase.')}
            {t("Double-check to make sure it's correct.")}
        </p>

        <SeedArea>
            {phrases.length > 0 ? phrases.map((phrase, index) => <span key={index}>{phrase}</span>) : t('Select keywords')}
        </SeedArea>

        <Seed>
            {randomizedPhrases.map((phrase, index) =>
                <Phrase
                    onClick={phrases.includes(phrase) ? () => removePhrase(phrase) : () => addPhrase(phrase)}
                    selected={phrases.includes(phrase)} key={index}
                >
                    {phrase}
                </Phrase>
            )}
        </Seed>
        <Footer>
            <Button
                disabled={!isValid()}
                variant={'primary'}
                isLoading={isButtonLoading}
                onClick={importWallet}
            >
                {isValid() === false ? t('Invalid seed!') : t('Confirm')}
            </Button>
        </Footer>
    </>
}

const Seed = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`

const Phrase = styled.li`
    display: inline-block;
    background: var(--dbg);
    border-radius: 20px;
    padding: 5px 10px;
    margin: 0 10px 10px 0;
    cursor: pointer;
    border: 1px solid var(--dbg);
    
    &:hover {
        border-color: #35A6B6;
    }
    
    ${props => props.selected && css`
        color: #35A6B6;
        border-color: #35A6B6;
    `}
`
const SeedArea = styled.div`
    border-radius: 20px;
    padding: 10px 15px;
    background: var(--dbg);
    height: 150px;
    margin-bottom: 10px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.3);    
    
    span {
        display: inline-block;
        margin: 0 15px 5px 0;
        color: #ffffff;
    }
`

export default Confirm
