import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '@/components/Button'
import { BuyHeader } from './components/BuyHeader'
import { Price, PriceWrap } from '../../components/Price'
import ItemCard from './components/ItemCard'
import Footer from '../Modal/Footer'
import Performance from '../../components/Performance'
import { SocksImages } from '../../../../components/NftImages'
import styled from 'styled-components'

const Sock = ({ item, setOpen, handleBuy, isLoading, isLoggedIn }) => {
    const { t } = useTranslation()
    return item && (
        <>
            <BuyHeader />
            <Content>
                <div>
                    <SockCard>
                        <div>{item.title}</div>
                        <img
                            src={SocksImages[item.type]}
                            alt={item.title}
                        />
                        <div>
                            <div>
                                <Performance item={item} showTitle />
                            </div>
                        </div>
                    </SockCard>
                </div>

                <PriceWrap>
                    <Price item={item} showAll />
                    {item.title}
                </PriceWrap>
            </Content>
            <Footer>
                <Button onClick={() => setOpen(false)}>{t('Cancel')}</Button>
                {isLoggedIn
                    ? <Button isLoading={isLoading} onClick={() => handleBuy({ socks_id: item.id })} variant={'primary'}>{t('Buy')}</Button>
                    : <Link
                        onClick={() => {
                            setOpen(false)
                            window.scrollTo(0, 0)
                        }}
                        to="/login"
                    >
                        <Button variant="primary">{t('Login')}</Button>
                    </Link>}
            </Footer>
        </>
    )
}

const SockCard = styled(ItemCard)`
    img {
        margin: 50px 0 0 10%;
    }
`

const Content = styled.div`
    max-width: 300px;
    margin: 0 auto;
    
    div${PriceWrap} {
        justify-content: center;
        margin-top: 20px;
    }
`

export default Sock
