import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'
import StringAppear from '@/components/StringAppear'
import { scrollWithOffset } from '@/utils/hashLink'
import { useTranslation } from 'react-i18next'
import ImgBackground from '@/assets/banner/background.png'
import ImgShoes from '@/assets/banner/shoes.png'
import Button from '@/components/Button'

const Banner = () => {
    const { t } = useTranslation()
    return (
        <BannerContainer>
            <Content>
                <h2>
                    <StringAppear string='Unique collectables' />
                </h2>
                <p>
                    {t('Walk your dog. Jog in the park. Hike a mountain. Stay active.')}
                </p>
                <HashLink to={'/#marketplace'} scroll={el => scrollWithOffset(el)}>
                    <Button variant={'primary'}>{t('Shop shoes')}</Button>
                </HashLink>
            </Content>
        </BannerContainer>
    )
}

const Content = styled.div`
    font-size: 20px;
    font-weight: 300;
    padding: 100px;
    @media (max-width: 1200px) {
        padding: 50px;
    }
    @media (max-width: 767px) {
        padding: 25px;
    }

    h2 {
        font-size: 48px;
        font-weight: 600;
        @media (max-width: 767px) {
            font-size: 36px;
        }
    }

    p {
        margin-bottom: 60px;
        @media (max-width: 767px) {
            margin-bottom: 190px;
        }
    }
`

const BannerContainer = styled.div`
    background-image: url(${ImgBackground}), url(${ImgShoes}), radial-gradient(60.99% 50% at 50% 50%, #407687 0%, #213742 100%);
    background-repeat: no-repeat, no-repeat;
    background-position: top right, center right;
    background-size: auto 100%, auto 100%;
    border-radius: 20px;
    display: flex;

    @media (max-width: 1200px) {
        background-size: auto 100%, auto 80%;
    }

    @media (max-width: 767px) {
        background-position: top right, bottom 65px center;
        background-size: auto 100%, auto 200px;
    }
`

export default Banner
