import styled, { css } from 'styled-components'

const StyleReset = css`
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: none;
        -webkit-text-fill-color: var(--text);
        -webkit-box-shadow: 0 0 0px 1000px var(--dbg) inset;
        transition: background-color 5000s ease-in-out 0s;
    }
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance:textfield;
    }
    input, label {
        display: block;
    }
    input {
        outline: none;
        border: none;
        appearance: none;
    }
`

const StyledInput = styled.div`
    ${StyleReset};
    input {
        padding: 10px 15px;
        background: var(--text);
        color: var(--dbg);
        border-radius: 20px;
        width: 100%;
        font-size: 14px;    
    }
    label {
        padding: 5px 15px;
        font-weight: 700;
    }
`
const BoxedInput = styled.div`
    ${StyleReset};
    &, input {
        background: var(--dbg);
    }
    & > div:first-child {
        flex: 1;
    }
    input {
        font-size: 16px;
        color: var(--text);
        width: 100%;
        &:disabled {
            cursor: not-allowed
        }
    }
    label {
        color: var(--special);
    }
    margin-top: 20px;
    border-radius: 10px;
    padding: 20px;
    ${({ buttonInline }) => buttonInline ? BoxedDisplay : `
        @media (min-width: 600px) {
            ${BoxedDisplay}
        }
    `}
    @media (max-width: 600px) {
        button {
            width: 100%;
        }
    }
`
const BoxedDisplay = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Input = ({ label, value, onChange, textarea, variant, button, buttonInline, ...rest }) => (
    variant === 'boxed' ? (
        <BoxedInput {...rest} buttonInline={buttonInline}>
            <div>
                <label htmlFor={label}>{label}</label>
                <input name={label} value={value} onChange={(e) => onChange(e.target.value)} {...rest} />
            </div>
            {button}
        </BoxedInput>
    ) : (
        <StyledInput {...rest}>
            <label htmlFor={label}>{label}</label>
            {
                textarea ? (
                    <textarea name={label} value={value} onChange={(e) => onChange(e.target.value)} {...rest} />
                ) : (
                    <input name={label} value={value} onChange={(e) => onChange(e.target.value)} {...rest} />
                )
            }
        </StyledInput>)
)
export default Input
