import { Link } from 'react-router-dom'
import Button from '@/components/Button'
import { Grid } from '@/components'
import { useTranslation } from 'react-i18next'
import { BuyHeader } from './components/BuyHeader'
import { Price, PriceWrap } from '../../components/Price'
import ItemCard from './components/ItemCard'
import Footer from '../Modal/Footer'
import { WaterImages } from '../../../../components/NftImages'
import React from 'react'
import styled from 'styled-components'

const Water = ({ item, setOpen, handleBuy, isLoading, isLoggedIn }) => {
    const { t } = useTranslation()
    return item && (
        <>
            <BuyHeader />
            <Content>
                <Grid col={1}>
                    <div>
                        <WaterCard>
                            <div>{item.title}</div>
                            <img
                                src={WaterImages[item.capacity]}
                                alt={item.title}
                            />
                            <div>
                                <div>
                                    {item.capacity / 1000} l
                                </div>
                            </div>
                        </WaterCard>
                    </div>

                    <PriceWrap>
                        <Price item={item} showAll />
                        {item.title}, {item.capacity / 1000}l
                    </PriceWrap>
                </Grid>
            </Content>
            <Footer>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                {isLoggedIn
                    ? <Button isLoading={isLoading} onClick={() => handleBuy({ water_id: item.id })} variant={'primary'}>{t('Buy')}</Button>
                    : <Link
                        onClick={() => {
                            setOpen(false)
                            window.scrollTo(0, 0)
                        }}
                        to="/login"
                    >
                        <Button variant="primary">{t('Login')}</Button>
                    </Link>}
            </Footer>
        </>
    )
}

const WaterCard = styled(ItemCard)`
    img {
        margin: 30px 0;
    }
`

const Content = styled.div`
    max-width: 300px;
    margin: 0 auto;
    
    div${PriceWrap} {
        justify-content: center;
        margin-top: 20px;
    }
`

export default Water
