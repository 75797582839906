import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Grid } from '@/components'

const Wrap = styled(Grid)`
    margin-bottom: 20px;
    & > div {
        width: 100%;
        height: 300px;
        border-radius: 10px;
        background: linear-gradient(-45deg, var(--dbg), var(--subbg), var(--subbg));
        background-size: 400% 100%;
        animation: gradient 3s ease infinite;
        transition: all 0.9s;
        filter: blur(2px);
        @keyframes gradient {
            0% {
                background-position: 0% 50%;
                opacity: 1;
            }
            50% {
                background-position: 50% 50%;
                opacity: 0.5;
            }
            100% {
                background-position: 0% 50%;
                opacity: 1;
            }
        }
    }
`

const PageLoader = ({ isLoading, children, col = 5 }) => {
    const [show, setShow] = useState(!isLoading)

    useEffect(() => {
        if (show) {
            setShow(false)
        }
        setTimeout(() => setShow(true), 700)
    }, [isLoading])

    return !show ? (
        <Wrap col={col} mobileGap={20}>
            <div />
            <div />
            <div />
            <div />
            <div />
        </Wrap>
    ) : children
}

export default PageLoader
