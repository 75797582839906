import styled, { css } from 'styled-components'
import { CardBase } from '../../../../../components'

export const ItemCard = styled.div`
   ${CardBase}
   position: relative;
   padding: 0;
   text-align: center;
   box-shadow: -1px 4px 12px -1px rgba(0,0,0,0.3);
   text-transform: capitalize;
   max-width: 350px;
   min-height: 250px;
   margin: 0 auto;
   
   @media (max-width: 767px) {
      max-width: 250px;
      min-height: 200px;
   }
   
    img, video {
        width: 100%;
        z-index: 1;
        border-radius: 10px;
    }
   
   & > div {
      position: absolute;
      left: 0;
      width: 100%;
      padding: 15px;
      z-index: 2;
   }
   
   & > div:first-child {
      top: 0;
      font-size: 16px;
      font-weight: 600;
   }
   
   & > div:last-child {
      bottom: 0;
      font-weight: normal;
      
      & > div {
         display: inline-block;
         border-radius: 20px;
         background-color: var(--lbg);
         font-size: 12px;
         padding: 0 15px;
         line-height: 22px;
         
         b {
            font-weight: 600;
         }
      }
   }
`

export const ItemHeader = styled.div`
   display: flex;
   justify-content: space-between;
   padding: 10px 15px;
`

export const ItemControls = styled.div`
   display: flex;
`

export const ItemControl = styled.span`
   cursor: pointer;
   color: #ffffff96;
   padding: 5px 7px;
   border-radius: 5px;
      
   &:hover {
      color: #ffffff;
   }
      
   ${props => props.active &&
      css`
         background: #FFFFFF26;
         color: #ffffff;
   `};
`

export default ItemCard
