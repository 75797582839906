import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ImgTransfer } from '@/assets/ico-transfer.svg'
import { ReactComponent as ImgReceive } from '@/assets/ico-receive.svg'
import { ActionsContent } from '../index'

const Actions = ({ showTransfer, showReceive }) => {
    const { t } = useTranslation()
    return (
        <ActionsContent>
            <div onClick={showTransfer}>
                <ImgTransfer />
                {t('Transfer')}
            </div>
            <div onClick={showReceive}>
                <ImgReceive />
                {t('Receive')}
            </div>
        </ActionsContent>
    )
}

export default Actions
