import {useEffect, useState} from 'react'
import styled from 'styled-components'
import Select from '@/components/Select'
import i18n from 'i18next'
import { useStore } from '../../utils/hooks'

const StyledSelect = styled(Select)`
    @media (min-width: 1164px) {
        margin-left: 20px;
    }
    @media (max-width: 1164px) {
        height: 40px;
        &, li {
            padding: 10px 0;
            width: 40px;
            text-align: center;
        }
    }
`

const LanguageSelect = ({ mob }) => {
    const store = useStore()
    const [selected, setSelected] = useState('')
    const [options, setOptions] = useState([])
    const [mobOptions, setMobOptions] = useState([])

    const LngChange = e => {
        setSelected(e)
        localStorage.setItem('selectedLanguage', e.value)
        i18n.changeLanguage(typeof e === 'string' ? e : e?.value)
        // document.location.reload()
    }

    useEffect(() => {
        setSelected(
            (mob ? mobOptions : options).find(i => i.value === (localStorage.getItem('selectedLanguage') || 'en'))
        )
    }, [options, mobOptions])

    useEffect(() => {
        const options = []
        const mobOptions = []

        if (store.settings.languages) {
            Object.keys(store.settings.languages).forEach(key => {
                const language = store.settings.languages[key]
                options.push({
                    value: language.key,
                    name: language.name,
                })

                mobOptions.push({
                    value: language.key,
                    name: language.name,
                })
            })
        }

        setOptions(options)
        setMobOptions(mobOptions)
    }, [JSON.stringify(store.settings.languages)])

    return (
        <StyledSelect
            value={selected}
            onChange={LngChange}
            items={mob ? mobOptions : options}
        />
    )
}

export default LanguageSelect
