import styled from 'styled-components'

export const SelectWrap = styled.div`
    background: var(--dbg);
    padding: 20px;
    border-radius: 10px;
`
export const LockedBoxes = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
    & > div {
        background: var(--dbg);
        padding: 20px;
        border-radius: 10px;
        text-align: center;
        p {
            font-size: 12px;
            color: var(--special);
            margin-bottom: 5px;
        }
        b {
            font-size: 18px;
        }
    }
`
export const Wrap = styled.div`
    max-width: 900px;
    margin: 40px auto 0;
    padding: 25px 20px;
    background: var(--subbg);
    border-radius: 10px;
    text-align: left;
    p {
        font-size: 14px;
        margin: 20px 0;
    }
    button {
        margin-top: 20px;
    }
    & > div {
        max-width: 480px;
        margin: 0 auto;
        & > div + p {
            color: var(--subtext);
        }
    }
`
export const Error = styled.div`
    color: var(--red);
    font-size: 12px;
    margin-top: 10px;
`
export const LockedItem = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
        height: 20px;
    }
    p:last-child {
        color: var(--subtext);
    }
`