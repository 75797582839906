import styled, {css} from 'styled-components'

const Footer = ({ children, fullWidth }) => {
    return (
        <StyledFooter fullWidth={fullWidth}>
            {children}
        </StyledFooter>
    )
}

const StyledFooter = styled.div`
    text-align: center;
    margin-top: 40px;
    @media (max-width: 767px) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    & > button {
        margin: 0 10px;
        width: 130px;
        ${props => props.fullWidth &&
        css`
          margin: 0;
          width: 100%;
        `};
        
        @media (max-width: 767px) {
            width: 40%;
            
        ${props => props.fullWidth &&
            css`
              margin: 0;
              width: 100%;
            `};
        }
    }
`

export default Footer
