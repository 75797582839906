import styled, { css } from 'styled-components'
import { CardBase } from '@/components'

export const Wrap = styled.section`
    padding: 120px 0 80px;
    h1 {
        margin-bottom: 40px;
    }
    & > div > div:first-of-type {
        margin-bottom: 40px;
    }
    @media (max-width: 767px) {
        padding: 100px 0 40px;
    }
`

export const ItemPreview = styled.div`
    ${props => props.clickable &&
        css`
            cursor: pointer;
        `};
    position: relative;
    @media (min-width: 1700px) {
        & > img {
            min-height: 300px;
        }
    }
    @media (max-width: 1700px) {
        & > img {
            min-height: 150px;
        }
    }
    & > div {
        position: absolute;
        width: 100%;
        left: 0;
        padding: 15px;
        @media (max-width: 767px) {
            padding: 8px;
        }
        h4:first-letter {
            text-transform: capitalize;
        }
    }
    
    & > div:nth-of-type(1) {
        top: 0;
    }
    
    & > div:nth-of-type(2) {
        bottom: 0;
        
        & > div {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 12px;
            svg {
                margin-right: 5px;
            }
            
            :nth-of-type(4) {
                flex: 1;
                color: #FFFFFF;
                opacity: 0.6;
            }
        }
    }
`

export const Card = styled.div.attrs(props => ({
    style: {
        background: props.mousePos && `radial-gradient(
            47.92% 41.32% at ${props.mousePos.x}px ${props.mousePos.y}px,
            #366877 0%, #213742 100%
        )`}
}))`
    ${CardBase}
    padding: 0;
    position: relative;
    z-index: 2;

    &::before {
        content: "";
        background: inherit;
        position: absolute;
        z-index: 0;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        border-radius: 10px;
        transition: opacity 0.5s;
    }
`

export const MiniWrap = styled.section`
    padding: 10px 0 10px;
    & > div > div:first-of-type {
        margin-bottom: 10px;
    }
    svg {
        height: 30px;  
    }
    @media (max-width: 767px) {
        padding: 10px 0 10px;
    }
`

export const MiniHeader = styled.section`
    margin:5% auto 10% auto;
    background:none;
    border:0;
    outline:none;
    width:100%;
    svg {
        height: 50px;
        margin: 0 auto 5% -6%;
    }
    .w3m-core-button {
        text-align: -webkit-center;
    }
`