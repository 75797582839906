import React, { useMemo } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { formatAmount } from '@/utils/amount'
import { useStore } from '@/utils/hooks'


const Balances = () => {
    const store = useStore()
    const { t } = useTranslation()

    const balances = useMemo(() => {
        const result = []

        store.settings.assets?.forEach(asset => {
            if (asset.active) {
                result.push({
                    ...asset, balance: store.user.wallet[asset.ticker.toLowerCase()]
                })
            }
        })

        return result
    }, [store.settings, store?.user])

    return (
        <>
            <h3>{t('My spending account')}</h3>
            {balances.length > 0 ?
                <Content>
                    {balances?.map((item, index) => (
                        <div key={index}>
                            <Asset>
                                <img src={item.image} alt={item.ticker} />
                                <strong>{item.ticker}</strong>
                            </Asset>
                            <span>{formatAmount(item.balance)}</span>
                        </div>
                    ))}
                </Content>
                :
                <p>{t('Your balance is empty')}</p>
            }
        </>
    )
}

export const Content = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    
    img {
        width: 25px;
        margin-right: 6px;
    }
    
    & > div {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        
        &:last-child {
            margin-bottom: 0px;
        }
                
        span {
            opacity: 0.8;
        }
        
        &:hover {
            span {
                opacity: 1;
            }
        }
    }
`

export const Asset = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    
    strong {
        font-weight: 400;
    }
`

export default observer(Balances)
