import { useTranslation } from 'react-i18next'

export const BuyHeader = () => {
    const { t } = useTranslation()
    return (
        <>
            <h3>{t('Purchase item')}</h3>
            <p>{t('To confirm your purchase press buy button')}</p>
        </>
    )
}
