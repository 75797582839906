import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { toastError, toastSuccess } from '@toast'
import { post } from '@api'
import { useStore } from '@/utils/hooks'
import Success from './Modal/Success'
import Error from './Modal/Error'
import BuyTypes from './Modal/BuyTypes'
import { default as BuyShoe } from './Items/Shoe'
import { default as BuySock } from './Items/Sock'
import { default as BuyWater } from './Items/Water'
import { discountedPrice } from '../../../utils/amount'

const BuyModal = ({ item, setClose, type }) => {
    const store = useStore()
    const { t } = useTranslation()

    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [buyId, setBuyId] = useState(false)
    const [discountPercent, setDiscountPercent] = useState(0)
    const [discountCode, setDiscountCode] = useState('')

    const handleBuy = async (body) => {
        // to:do if in future there're more crypto than bnb to buy, check this.

        let asset = 'tip'
        if (type === 'shoes') {
           asset = 'bnb'
        }

        if (!(asset in store.user.wallet)) {
            setShowError(true)
            return
        }

        const price = discountedPrice(item.prices[asset], discountPercent)
        const hasBalance = Number(store.user.wallet[asset]) >= Number(price) // todo

        if (!hasBalance) {
            setShowError(true)
            return
        }

        if (discountPercent > 0) {
            body.discount_code = discountCode
        }

        try {
            setIsLoading(true)
            const result = await post({
                path: `market/${type}/buy`,
                body,
                token: store.tokens.token
            })

            if (result.message) {
                if ('errors' in result && result.errors.length > 0) {
                    toastError(`${t('Purchasing error')}: ${result.errors[0]}`)
                } else {
                    toastSuccess(t(result.message))
                    setShowSuccess(true)
                    await store.initUser()
                }

                return
            }

            setShowSuccess(true)
        } catch (err) {
            toastError(`${t('Purchasing error')}: ${err}`)
        } finally {
            setIsLoading(false)
        }
    }

    const handleBuyFiat = async (body) => {
        try {
            setIsLoading(true)
            const params = { ...body, currency: 'eur' }

            if (discountPercent > 0) {
                params.discount_code = discountCode
            }

            const result = await post({
                path: `market/${type}/buy`,
                body: params,
                token: store.tokens.token
            })

            if (result?.respStatus === 200) {
                window.location = result.redirect_url
                return
            }
            if (result?.errors.length > 0) {
                toastError(`${t('Purchasing error')}: ${result.errors[0]}`)
            } else {
                toastError(t('Purchasing error'))
            }

        } catch (err) {
            toastError(`${t('Purchasing error')}: ${err}`)
        } finally {
            setIsLoading(false)
        }
    }

    const renderBody = () => {
        if (showSuccess) {
            return <Success item={item} setOpen={setClose} discount={discountPercent} />
        }

        if (showError) {
            return <Error
                discount={discountPercent}
                item={item}
                setOpen={setClose}
                title={t('Insufficient Funds')}
                message={t('Please transfer additional funds from your wallet to your spending account to continue.')}
            />
        }

        if (buyId) {
            return <BuyTypes
                isLoading={isLoading}
                setOpen={setClose}
                item={item}
                handleBuyFiat={() => handleBuyFiat(buyId)}
                handleBuyCrypto={() => handleBuy(buyId)}
                discountPercent={discountPercent}
                setDiscountPercent={setDiscountPercent}
                discountCode={discountCode}
                setDiscountCode={setDiscountCode}
            />
        }

        switch (type) {
            case 'shoes':
                return <BuyShoe
                    item={item}
                    setOpen={setClose}
                    handleBuy={setBuyId}
                    isLoading={isLoading}
                    isLoggedIn={!!store.user}
                    discountPercent={discountPercent}
                    setDiscountPercent={setDiscountPercent}
                    discountCode={discountCode}
                    setDiscountCode={setDiscountCode}
                />
            case 'socks':
                return <BuySock item={item} setOpen={setClose} handleBuy={handleBuy} isLoading={isLoading} isLoggedIn={!!store.user} />
            case 'water':
                return <BuyWater item={item} setOpen={setClose} handleBuy={handleBuy} isLoading={isLoading} isLoggedIn={!!store.user} />
            default:
                console.error('Invalid item type: ' + type)
                return
        }
    }

    return (
        <Wrap>
            {renderBody()}
        </Wrap>
    )
}

export const Wrap = styled.div`
    max-width: 600px;
    width: 100%;
    margin: auto;
    h3 {
        font-size: 20px;
        @media (max-width: 767px) {
            font-size: 18px;
        }
        &, & + p {
            text-align: center;
        }
        & + p {
            margin: 15px 0 25px 0;
            font-size: 16px;
            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }
    @media (min-width: 640px) {
        min-width: 600px
    }
`

export const Content = styled.div`
    display: flex;
    justify-content: center;
`

export default BuyModal
