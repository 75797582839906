import React from 'react'
import { useStore } from '@/utils/hooks'
import PlaceholderImg from '@/assets/funds.svg'

export const AssetIco = ({ ticker, size = 20 }) => {
    const store = useStore()

    const getAssetImage = (isCrypto) => {
        const getFrom = isCrypto ? store.settings.assets : store.settings.currencies
        return getFrom?.find(asset => asset?.ticker.toUpperCase() === ticker.toUpperCase())?.image
    }

    const resolveAssetImage = () => {
        const crypto = getAssetImage(true)

        if (crypto) {
            return crypto
        }

        const fiat = getAssetImage()

        if (fiat) {
            return fiat
        }

        return PlaceholderImg
    }

    return (
        <img
            src={resolveAssetImage()}
            alt={ticker}
            width={size}
            height={size}
            data-tip={ticker.toUpperCase()}
            data-place={'bottom'}
        />
    )
}

