import styled from 'styled-components'

const SelectWrap = styled.div`
    position: relative;
    cursor: pointer;
    background: #FFFFFF1A;
    font-weight: 600;
    &, ul {
        border-radius: 10px;
    }
    &, li {
        padding: 10px 20px;
    }
    &:not(:focus):hover {
        ul {
            max-height: 500px;
            opacity: 1;
        }
    }
`
const OptionsWrap = styled.ul`
    background: var(--dbg);
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.5s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    & > li {
        text-align: center;
        & + li {
            border-top: 1px solid var(--border);
        }
    }
`

const Select = ({
    items = [], label = 'Select...', value, onChange, ...rest
}) => {
    return (
        <SelectWrap {...rest} tabIndex="0">
            <div>{typeof value === 'string' ? value : value?.name || label}</div>
            <OptionsWrap>
                {items.map((i) => (typeof i === 'string' ? (
                    <li
                        key={i}
                        value={i}
                        onClick={() => onChange(i)}
                    >
                        {i}
                    </li>
                ) : (
                    <li
                        key={i.value}
                        value={i?.value}
                        onClick={() => onChange(i)}
                    >
                        {i.image} {i?.name}
                    </li>
                )))}
            </OptionsWrap>
        </SelectWrap>
    )
}

export default Select