import { toast } from 'react-toastify'

export const toastSuccess = (msg) => {
    toast.success(msg, {
        toastId: msg,
    })
}

export const toastError = (msg) => {
    toast.error(msg, {
        toastId: msg,
    })
}

export const toastWarning = (msg) => {
    toast.warning(msg, {
        toastId: msg,
    })
}

export const toastInfo = (msg) => {
    toast.info(msg, {
        toastId: msg,
    })
}
