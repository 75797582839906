import styled from 'styled-components'
import Modal from '@/components/Modal'
import { ReactComponent as ImgEmpty } from '@/assets/ico-empty.svg'
import { Block, Layout, Left, ModalWrapper, Right } from './index'
import Balance from './Wallet/Balance'
import Actions from './Wallet/Actions'
import { useState } from 'react'
import Receive from './Wallet/Receive'
import Balances from './Wallet/Balances'
import Transfer from './Transfer'
import NFTs from './NFT/SideAccordion'

const Wallet = () => {
    const [showTransfer, setShowTransfer] = useState(true)
    const [showReceive, setShowReceive] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const renderContent = () => {
        if (showTransfer) {
            return <Transfer defaultSender={'wallet'} />
        } else if (showReceive) {
            return <Receive />
        } else {
            return <Empty><ImgEmpty /></Empty>
        }
    }

    const handleShowTransfer = () => {
        setShowTransfer(true)
        setShowReceive(false)
        setShowModal(true)
    }

    const handleShowReceive = () => {
        setShowReceive(true)
        setShowTransfer(false)
        setShowModal(true)
    }

    return (
        <Layout>
            <Left>
                <Block>
                    <Balance />
                </Block>
                <Actions
                    showTransfer={handleShowTransfer}
                    showReceive={handleShowReceive}
                />
                <Block>
                    <Balances />
                </Block>
                <Block>
                    <NFTs />
                </Block>
            </Left>
            {window.innerWidth > 800 ?
                <Right>
                    <Block>
                        {renderContent()}
                    </Block>
                </Right>
                :
                <Modal open={showModal} setOpen={() => setShowModal(false)} ariaHideApp={false}>
                    <ModalWrapper>
                        {renderContent()}
                    </ModalWrapper>
                </Modal>
            }
        </Layout>
    )
}

const Empty = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
`

export default Wallet
