import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Container } from '@/components'
import Tabs from '@/components/Tabs'
import { get } from '@api'
import { useStore } from '@hooks'
import Helmet from '@/components/Helmet'
import Modal from '@/components/Modal'
import { Wrap } from './styled'
import Items from './Items'
import Shoe from './Items/Shoe'
import Sock from './Items/Sock'
import Water from './Items/Water'
import BuyModal from './Buy/BuyModal'
import JoinInstructions from '../JoinInstructions'
import Subscribe from '../Subscribe'
import Banner from '../Banner'
import Filters from './components/Filters'

const Marketplace = () => {
    const [tab, setTab] = useState(0)
    const [buyItem, setBuyItem] = useState(null)
    const [buyItemType, setBuyItemType] = useState(null)
    const [buyModalOpen, setBuyModalOpen] = useState(false)
    const [sort, setSort] = useState(null)
    const [filter, setFilter] = useState(null)

    const store = useStore()
    const nav = useNavigate()
    const { t } = useTranslation()

    const tabs = [t('shoes'), t('socks'), t('water')]

    useEffect(() => {
        const id = window.location.pathname.split('/shoes/')[1]
        if (id) {
            const sendId = id.split('/')[0];
            (async () => {
                const getItemResult = await get({ path: `market/shoe/${sendId}` })
                if (getItemResult.item) {
                    handleBuy(getItemResult.item, 'shoes')
                }
            })()
        }
    }, [])

    const handleBuy = (item, type) => {
        setBuyItemType(type)
        setBuyItem(item)
        setBuyModalOpen(true)
        if (store.user) {
            nav(`/${type}/${item.id}/a/${store.user.affiliate_code}`)
        } else {
            nav(`/${type}/${item.id}`)
        }
    }

    const handleModalClose = () => {
        setBuyModalOpen(false)
        setBuyItem(null)
        setBuyItemType(null)
        nav('/')
    }

    return (
        <>
            <Helmet shoe={buyModalOpen && buyItemType === 'shoes' && buyItem} />
            <Wrap>
                <Container>
                    <Banner />
                    {/* Empty link for scroll to id from banner */}
                    <a name={'marketplace'} id={'marketplace'} />
                    <Tabs items={tabs} active={tab} setActive={setTab} id={'marketplace'} />
                    <Filters type={tabs[tab]} sort={sort} setSort={setSort} filter={filter} setFilter={setFilter} />
                    <Items
                        render={(item) => <Shoe handleBuy={handleBuy} item={item} key={item.id} />}
                        type={tabs[0]}
                        show={tab === 0}
                        sort={sort}
                        filter={filter}
                    />
                    <Items
                        render={(item) => <Sock handleBuy={handleBuy} item={item} key={item.id} />}
                        type={tabs[1]}
                        show={tab === 1}
                        sort={sort}
                        filter={filter}
                    />
                    <Items
                        render={(item) => <Water handleBuy={handleBuy} item={item} key={item.id} />}
                        type={tabs[2]}
                        show={tab === 2}
                        sort={sort}
                        filter={filter}
                    />
                </Container>
                <Modal open={buyModalOpen} setOpen={handleModalClose} ariaHideApp={false}>
                    {buyModalOpen && <BuyModal item={buyItem} type={buyItemType} setClose={handleModalClose} />}
                </Modal>
            </Wrap>

            <JoinInstructions />
            <Subscribe />
        </>
    )
}

export default observer(Marketplace)
