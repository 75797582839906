import React, { useState, useMemo, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import Input from '@/components/Input'
import Button from '@/components/Button'
import { formatAmount } from '@/utils/amount'
import { useStore } from '@hooks'
import { toastSuccess, toastError } from '@toast'
import { post } from '@api'
import { ReactComponent as ImgDropdown } from '@/assets/ico-dropdown.svg'
import { AssetSelect, Asset } from '../Transfer/Assets'
import LockedInfo from './LockedInfo'
import { SelectWrap, Wrap, Error } from './styled'
import * as Sentry from '@sentry/browser'
import { t } from '@/utils'

const ICO = ({ searchParams }) => {
    const store = useStore()
    const balances = useMemo(() => {
        const result = []
        const invalidAssets = ['tip', 'top']
        store.settings.assets?.forEach(asset => {
            if (asset.active && !invalidAssets.includes(asset.ticker.toLowerCase())) {
                result.push({
                    ...asset, balance: store.user.wallet[asset.ticker.toLowerCase()]
                })
            }
        })

        return result
    }, [store.settings, store.user.wallet])

    const [amount, setAmount] = useState(!isNaN(searchParams.amount) ? searchParams.amount : '')
    const [topAmount, setTopAmount] = useState('')
    const [error, setError] = useState('')
    const [showAssets, setShowAssets] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const initialAsset = balances.find(i => i.ticker.toLowerCase() === searchParams.currency?.toLowerCase()) || balances[0]
    const [selectedAsset, setSelectedAsset] = useState(initialAsset)

    useEffect(() => {
        if (amount) {
            const topPerDollar = store.settings.ico?.ico_price_in_usd

            let minPurchaseSelectedAsset

            if (selectedAsset.ticker.toLowerCase() === 'bnb') {
                minPurchaseSelectedAsset = 0.5
            } else {
                minPurchaseSelectedAsset = store.settings.ico?.ico_min_buy_in_usd
            }

            if (Number(selectedAsset.balance) < Number(amount)) {
                setError(t(`Insufficient ${selectedAsset.ticker} balance`))
            } else if (minPurchaseSelectedAsset > amount) {
                setError(t('Minimum amount') + ` - ${formatAmount(minPurchaseSelectedAsset, 2)} ${selectedAsset.ticker}`)
            } else {
                setError('')
            }
            const topPerAmount = selectedAsset.ticker.toLowerCase() === 'bnb' ? store.settings.main_token_price / topPerDollar : 1 / topPerDollar
            setTopAmount(formatAmount(topPerAmount * amount, 2))
            return
        }
        setError('')
        setTopAmount('')
    }, [amount])

    const onBlur = () => {
        if (amount !== '') {
            setAmount(formatAmount(amount))
        }
    }

    const clearState = () => {
        setAmount('')
        setError('')
    }

    const handleSelectItem = (item) => {
        setSelectedAsset(item)
        setShowAssets(false)
        clearState()
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        try {
            const body = {
                amount: amount.toString(),
                asset: selectedAsset.ticker.toLowerCase()
            }
            const result = await post({ path: 'ico/exchange', body, token: store.tokens.token })
            if (result.message) {
                toastError(result.message)
                return
            }
            await store.initUser()
            toastSuccess(t('Transaction successful'))
        } catch (err) {
            Sentry.captureException(err)
            toastError(err?.message || t('There was an error, please try again later.'))
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Wrap>
            <div>
                <h3>{t('Public Sale Round 3 Final')}</h3>
                <p>
                    {t('As of August 10th TIPTOP enters the Public Sale Round 3 Final stage! Introducing TOP token which will be the major edition to our ecosystem and will have various utilities.')}
                </p>
                <SelectWrap>
                    <AssetSelect>
                        {balances.map((item) => {
                            const isActive = item.ticker.toLowerCase() === selectedAsset.ticker.toLowerCase()
                            return (showAssets || isActive) &&
                                <Asset
                                    active={showAssets && isActive}
                                    key={item.ticker}
                                    onClick={() => {
                                        if (showAssets) return handleSelectItem(item)
                                        setShowAssets(true)
                                    }}
                                >
                                    <img
                                        src={item.image}
                                        alt={item.ticker}
                                    />
                                    <div>
                                        {item.ticker.toUpperCase()}
                                        <span>Balance: {formatAmount(item.balance)}</span>
                                    </div>
                                    {!showAssets && <ImgDropdown />}
                                </Asset>
                        })}
                    </AssetSelect>
                </SelectWrap>
                <Input
                    label={t('Amount')}
                    onBlur={onBlur}
                    onChange={setAmount}
                    value={amount}
                    type="number"
                    placeholder={'0.00'}
                    variant="boxed"
                    buttonInline
                    button={
                        <b>
                            <img src={selectedAsset.image} height="20px" alt={selectedAsset.ticker} /> {selectedAsset.ticker}
                        </b>
                    }
                />
                <Input
                    label={t('You get')}
                    onBlur={onBlur}
                    onChange={setTopAmount}
                    value={topAmount}
                    type="number"
                    placeholder={'0.00'}
                    variant="boxed"
                    buttonInline
                    disabled
                    button={
                        <b>
                            <img src={store.settings.assets.find(i => i.ticker === 'TOP')?.image} height="20px" alt="TOP" /> TOP
                        </b>
                    }
                />
                <LockedInfo topAmount={topAmount} />
                {error && <Error>{error}</Error>}
                <Button
                    fullWidth
                    disabled={!amount || !!error}
                    onClick={handleSubmit}
                    isLoading={isLoading}
                >Buy</Button>

                <p style={{ opacity: 0.7 }}>
                    <strong style={{ fontWeight: 500 }}>Sale details:</strong><br />
                    <li>Sale duration: 2023-08-10 - 2023-08-15</li>
                    <li>Token price: $0.03</li>
                    <li>Minimum buy in 0.5 BNB or equivalent in USDT / BUSD</li>
                    <li>25% of tokens bought are distributed instantly</li>
                    <li>75% of tokens bought are locked for a 1 month period</li>
                </p>
            </div>
        </Wrap>
    )
}

export default observer(ICO)
