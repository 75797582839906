import qs from 'qs'

const API_URL = 'https://sfjtzvvdks.tiptop.io/api/'
// const API_URL = 'https://dev-api.tiptop.io/api/'

export const handleApi = async ({ path, type = 'GET', body, token = '' }) => {
    const result = await fetch(`${API_URL}${path}`, {
        method: type,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: body ? JSON.stringify(body) : '',

    }).then((response) => response.json()).catch((e) => console.log(path, ' error: ', e))
    return result
}

export const get = async ({ path, token = '', params = {} }) => {
    let queryParams = ''

    if (Object.keys(params).length > 0) {
        queryParams = '?' + qs.stringify(params)
    }

    const headers = {
        'Content-Type': 'application/json'
    }
    if (token) {
        headers['Authorization'] = 'Bearer ' + token
    }

    let status

    const result = await fetch(`${API_URL}${path}${queryParams}`, {
        method: 'GET',
        headers: headers,
    })
        .then((response) => {
            status = response.status
            return response.json()
        })
        .catch((e) => console.log(path, ' error: ', e))

    if (result) {
        result.respStatus = status
    }
    return result
}

export const post = async ({ path, token = '', body }) => {
    let status

    const result = await fetch(`${API_URL}${path}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: body ? JSON.stringify(body) : '',

    })
        .then((response) => {
            status = response.status
            return response.json()
        })
        .catch((e) => console.log(path, ' error: ', e))

    if (result) {
        result.respStatus = status
    }
    return result
}

export const handleLogin = async (body) => {
    const result = await fetch(`${API_URL}login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    }).then((response) => response.json()).catch((e) => console.log('handleLogin error: ', e))
    return result
}

export const handleRefreshToken = async (body) => {
    const result = await fetch(`${API_URL}token/refresh`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    }).then((response) => response.json()).catch((e) => console.log('handleRefreshToken error: ', e))
    return result
}

export const handleVerification = async (body) => {
    const result = await fetch(`${API_URL}verification`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    }).then((response) => response.json()).catch((e) => console.log('handleVerification error: ', e))
    return result
}

export const handleGetUser = async (token) => {
    const result = await fetch(`${API_URL}api/user`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then((response) => response.json()).catch((e) => console.log('handleGetUser error: ', e))
    return result
}

export const handleGetWallet = async (token) => {
    const result = await fetch(`${API_URL}wallet/balance`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then((response) => response.json()).catch((e) => console.log('handleGetWallet error: ', e))
    return result
}

export const handleAddAffiliate = async (body) => {
    const result = await fetch('https://sfjtzvvdks.tiptop.io/public/affiliate', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
        },
    }).then((response) => response.json()).catch((e) => console.log('handleAddAffiliate error: ', e))
    return result
}
