import styled, { css } from 'styled-components'

export const Container = styled.div`
    max-width: ${({ width = 1648 }) => `${width}px`};
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
`
export const Grid = styled.div`
    @media (min-width: ${({ disableAt }) => (disableAt ? `${disableAt}px` : '1024px')}) {
        display: grid;
        grid-template-columns: ${({ col, colExact }) => colExact || (col ? `repeat(${col}, minmax(0, 1fr))` : 'repeat(2, minmax(0, 1fr))')};
        grid-gap: ${({ gap }) => (gap ? `${gap}px` : '20px')};
        align-items: ${({ align }) => align || 'start'};
        justify-content: ${({ justify }) => justify || 'start'};
    }
    ${({ colL, colLExact, colLSize = 1600 }) => ((colL || colLExact) ? `
        @media (max-width: ${colLSize}px) {
            grid-template-columns: ${colLExact || (colL ? `repeat(${colL}, minmax(0, 1fr))` : 'repeat(2, minmax(0, 1fr))')}};
        }
    ` : '')}
    ${({ colM, colMExact, colMSize = 1300 }) => ((colM || colMExact) ? `
        @media (max-width: ${colMSize}px) {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: ${colMExact || (colM ? `repeat(${colM}, minmax(0, 1fr))` : 'repeat(2, minmax(0, 1fr))')}};
        }
    ` : '')}
    ${({ colXs, colXsExact, colXsSize = 600 }) => ((colXs || colXsExact) ? `
        @media (max-width: ${colXsSize}px) {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: ${colXsExact || (colXs ? `repeat(${colXs}, minmax(0, 1fr))` : 'repeat(2, minmax(0, 1fr))')}};
        }
    ` : '')}
    @media (max-width: ${({ disableAt }) => (disableAt ? `${disableAt}px` : '1024px')}) {
        ${({ mobileGap }) => mobileGap
        && `
                & > * + * {
                    margin-top: ${mobileGap}px;
                }
        `};
        ${({ gapMobile }) => gapMobile
        && `
            & > * {
                margin-top: ${gapMobile}px;
            }
        `};
    }
`
export const SimpleLink = ({ href, children, ...rest }) => (
    <a href={href} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
    </a>
)
export const H2Line = styled.span`
    @media (min-width: 1393px) {
        position: absolute;
        top: ${({ top }) => `${top || 100}px`};
        left: 0;
        height: 2px;
        background: var(--special);
        width: calc((100vw - 1340px) / 2);
    }
`
export const Flex = styled.div`
    display: flex;
    ${({ align }) => (align ? `align-items: ${align}` : '')};
    ${({ justify }) => (justify ? `justify-content: ${justify}` : '')};
    flex-direction: ${({ column }) => column ? 'column' : 'row'};
    ${({ grow }) => grow && `flex-grow: ${grow}`};
    @media (max-width: ${({ sm, xs, md }) => (xs ? '400px' : sm ? '767px' : md ? '1024px' : 0)}) {
        display: block;
    }
`
export const CardBase = css`
    padding: 10px;
    border-radius: 10px;
    background: radial-gradient(47.92% 41.32% at 50% 50%, #366877 0%, #213742 100%);
    img {
        width: 100%;
    }
    h4 {
        font-size: 14px;
    }
    @media (max-width: 500px) {
        h4 {
            font-size: 10px;
        }
    }
`
export const StyledScrollbar = css`
    ::-webkit-scrollbar {
        width: 2px;
    }
    ::-webkit-scrollbar-track {
        display: none;
    }
    ::-webkit-scrollbar-thumb {
        background: var(--dbg);
        :hover {
            background: var(--lbg);
        }
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: 2px;  /* Firefox */
`