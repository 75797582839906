import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { post } from '@/utils/api'
import { useStore } from '@/utils/hooks'
import Button from '@/components/Button'
import Input from '@/components/Input'
import { Footer } from '../WalletModal'

const ImportWallet = ({ onSuccess }) => {
    const store = useStore()
    const { t } = useTranslation()

    const [error, setError] = useState(null)
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [seed, setSeed] = useState('')

    const importWallet = async () => {
        try {
            setIsButtonLoading(true)
            const result = await post({
                path: 'wallet/import',
                token: store.tokens.token,
                body: {
                    seed,
                }
            })

            store.updateWallet(result)

            onSuccess()
        } catch (error) {
            setError(error)
        } finally {
            setIsButtonLoading(false)
        }
    }

    const isValid = () => {
        return seed.split(' ').length === 12
    }

    return <>
        <Textarea textarea label={t('Secret phrases')} onChange={setSeed} placeholder={t('Enter your secret phrase')} />

        <Footer>
            <Button
                disabled={!isValid()}
                variant={'primary'}
                isLoading={isButtonLoading}
                onClick={importWallet}
            >
                {t('Import wallet')}
            </Button>
        </Footer>
    </>
}

const Textarea = styled(Input)`
    textarea {
        border-radius: 20px;
        padding: 10px 15px;
        background: var(--dbg);
        height: 150px;
        margin-bottom: 10px;
        color: #fff;
        
        ::placeholder {
            font-size: 16px;
        }
    }
    
    label {
        font-weight: normal;
    }
`

export default ImportWallet
