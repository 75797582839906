import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { formatAmount } from '@/utils/amount'
import { useStore } from '@/utils/hooks'
import { discountedPrice } from '../../../utils/amount'

const fiat = ['EUR', 'USD']

export const Price = ({ item, showAll, discount }) => {
    const store = useStore()
    const allPrices = Object.entries(item.prices)
    const filterItems = !showAll ? allPrices.filter(price => price[0] === 'bnb') : allPrices

    return (
        <>
            <PriceStyled
                data-for={`info-${item.id}`}
                data-tip
            >
                {filterItems.map(i => {
                    const Item = (fiat.includes(i[0].toUpperCase()) ? store.settings.currencies : store.settings.assets)
                        ?.find(asset => asset.ticker.toUpperCase() === i[0].toUpperCase())
                    return (
                        <div
                            key={i[0]}
                        >
                            <img
                                src={Item?.image}
                                alt={i[0]}
                                data-tip={i[0].toUpperCase()}
                                data-place={'bottom'}
                            />
                            {discount > 0 ?
                                <DiscountedPrice>{formatAmount(discountedPrice(i[1], discount))} <span>{formatAmount(i[1])}</span></DiscountedPrice>
                                : formatAmount(i[1])
                            }
                        </div>
                    )
                })}

                <ReactTooltip />
            </PriceStyled>
            {!showAll && (
                <ReactTooltip
                    id={`info-${item.id}`}
                    data-event='click focus'
                >
                    {allPrices.filter(price => price[0] !== 'bnb').map(i => <div key={i[0]}>{i[0].toUpperCase()} <b>{discountedPrice(i[1], discount)}</b></div>)}
                </ReactTooltip>
            )}
        </>
    )
}

const PriceStyled = styled.div`
    font-weight: 600;
    background-color: #FFFFFF1A;
    border-radius: 10px;
    padding: 10px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        font-size: 12px;
    }
    
    img {
        margin-right: 5px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
    }
    
    & > div {
        display: flex;
        align-items: center;
        & + div {
            margin-left: 5px;
        }
    }
`

export const PriceWrap = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    text-transform: capitalize;
    
    & > :first-child {
        margin-right: 20px;
    }
`

const DiscountedPrice = styled.div`
    display: inline-block;
    
    span {
        text-decoration: line-through;
        font-weight: normal;
        opacity: 0.6;
        margin-left: 5px;
    }
`
