import { Helmet } from 'react-helmet'


const HelmetRender = ({ shoe }) => shoe ? (
    <Helmet>
        <title>{`Shoe #${shoe.id} / ${shoe.type}`}</title>
        <meta name="description" content={`#${shoe.id} / ${shoe.rarity} / ${shoe.type}`} />
        <meta property="og:image" content={shoe.image} />
        <link rel="apple-touch-icon" href={shoe.image} />
    </Helmet>
) : (
    <Helmet>
        <title>TipTop</title>
        <meta name="description" content="TipTop - Run WEB3" />
        <meta property="og:image" content="/logo_192.png" />
        <link rel="apple-touch-icon" href="/logo_192.png" />
    </Helmet>
)
export default HelmetRender