import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Flex } from '@/components'
import { ReactComponent as ImgKarma } from '@/assets/karma.svg'
import { ReactComponent as ImgSpeed } from '@/assets/speed.svg'
import { ReactComponent as ImgPerformance } from '@/assets/performance.svg'
import Button from '@/components/Button'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import ItemFooter from '../components/ItemFooter'
import { ItemPreview } from '../styled'
import Badges from '../components/Badges'
import Wrap from './Wrap'

const Shoe = ({ item, handleBuy, handleEquip, isEquipped }) => {
    const { t } = useTranslation()

    const badges = useMemo(() => {
        const badges = []

        if (item.rarity) {
            badges.push(<b>{item.rarity}</b>)
        }

        if (item.min_speed) {
            badges.push(<>{item.min_speed}-{item.max_speed} km/h</>)
        }

        if (item.level && item.max_level) {
            badges.push(<>Lvl {item.level} / {item.max_level}</>)
        }
        return badges
    }, [item])

    return (
        <>
            <Wrap key={item.id}>
                <ItemPreview onClick={() => handleBuy && handleBuy(item, 'shoes')} clickable={!!handleBuy}>
                    <Flex justify="space-between">
                        <h4>{item.type}</h4>
                        <Badges badges={badges} />
                    </Flex>
                    <img
                        src={item.image}
                        alt={item.name}
                    />
                    <Performance hasCode={item.code > 0}>
                        <div data-tip={'Performance'}>
                            <ImgPerformance />
                            {item.base_performance}
                        </div>
                        <div data-tip={'Stamina'}>
                            <ImgSpeed />
                            {item.base_stamina}
                        </div>
                        <div data-tip={'Karma'}>
                            <ImgKarma />
                            {item.base_karma}
                        </div>
                        {item.code > 0 && <div>#{item.code}</div>}
                    </Performance>
                </ItemPreview>
                {handleEquip && (
                    <EquipWrap>
                        <Button variant="primary" disabled={isEquipped} onClick={() => handleEquip(item.id)}>{isEquipped ? t('Equipped') : t('Equip')}</Button>
                        <Link to={`/wallet?id=${item.id}`}>
                            <Button disabled={isEquipped || item.isDemo}>{t('Transfer')}</Button>
                        </Link>
                    </EquipWrap>
                )}
                {handleBuy && <ItemFooter item={item} type={'shoes'} handleBuy={handleBuy} />}
            </Wrap>
            <ReactTooltip />
        </>
    )
}

const Performance = styled.div`
    display: grid;
    grid-template-columns: repeat(${({ hasCode }) => hasCode ? 4 : 3}, minmax(0, 1fr));
    grid-gap: ${({ hasCode }) => hasCode ? 10 : 30}px;
    justify-content: center;
    align-items: center;
    @media (min-width: 600px) {
        & > div:not(:nth-child(4)) {
            justify-content: ${({ hasCode }) => hasCode ? 'flex-start' : 'center'};
        }
        & > div:nth-child(4) {
            justify-content: right;
        }
    }
    @media (max-width: 600px) {
        grid-gap: 5px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        & > div {
            justify-content: center;
        }
        & > div:nth-child(4) {
            grid-column: 1 / 4;
        }
    }
`

const EquipWrap = styled.div`
    @media (min-width: 440px) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 10px;
    }
    padding: 10px;
    background: var(--subbg);
    border-radius: 0 0 10px 10px;
    button {
        padding: 6px 15px;
    }
    button, a {
        width: 100%;
    }
    @media (max-width: 440px) {
        & > button {
            margin-bottom: 5px;
        }
        button {
            padding: 5px;
        }
    }
`

export default observer(Shoe)
