import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '@/components/Input'
import Button from '@/components/Button'
import styled from 'styled-components'
import { handleVerification } from '@api'
import Cookies from 'js-cookie'
import { toastError, toastSuccess } from '@toast'

const Wrap = styled.section`
    padding: 200px 20px;
    max-width: 600px;
    margin: 0 auto;
    h1 {
        text-align: center;
    }
    form > div {
        width: 100%;
        margin: 20px 0 0;
    }
`

const Footer = styled.div`
    margin-top: 10px;
    text-align: center;
`

const AffiliateBox = styled.div`
    padding: 20px;
    background: #001B2680;
    border-radius: 10px;
    margin-bottom: 20px;
    p {
        text-align: center;
        b {
            color: var(--special);
        }
        & + p {
            margin-top: 20px;
        }
    }
`

const Verification = ({ setStep, setEmail, email }) => {
    const [error, setError] = useState('')
    const [affiliateInfo, setAffiliateInfo] = useState(null)

    const { t } = useTranslation()

    const affiliateCode = Cookies.get('affiliate_code')
    const affiliateCodeShow = Cookies.get('affiliate_code_show')
    const emailToRegister = Cookies.get('email_to_register')

    useEffect(() => {
        if (affiliateCode && affiliateCodeShow) {
            (async () => {
                const result = await fetch(`https://sfjtzvvdks.tiptop.io/public/affiliate?code=${affiliateCode}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then((response) => response.json()).catch((e) => console.log('Failed to get affiliate info ', e))
                if (result.email) {
                    setAffiliateInfo(result)
                }
            })()
        }
        if (emailToRegister) {
            setEmail(emailToRegister)
        }
    }, [])

    const verify = async (e) => {
        e.preventDefault()
        try {
            const body = { email }
            if (affiliateCode) {
                body.affiliate_code = affiliateCode
            }
            await handleVerification(body)
            toastSuccess(t('Success. Please, check your email.'))
            setStep(2)
            if (affiliateCode) {
                Cookies.remove('affiliate_code', { expires: 30, domain: 'tiptop.io' })
            }
            if (affiliateCodeShow) {
                Cookies.remove('affiliate_code_show', { expires: 30, domain: 'tiptop.io' })
            }
            if (emailToRegister) {
                Cookies.remove('email_to_register', { expires: 30, domain: 'tiptop.io' })
            }
        } catch (err) {
            setError(err)
            toastError(t('There was an error while logging in'))
        }
    }

    return (
        <Wrap>
            {affiliateInfo && (
                <AffiliateBox>
                    <p>{t('Welcome to TipTop. You\'ve been invited to join us by')} <b>{affiliateInfo.email}</b></p>
                    <p>{t('Join our worldwide community that chose to earn rewards through "Move 2 Earn" concept')}</p>
                </AffiliateBox>
            )}
            <h2>{t('Welcome')}!</h2>
            <h3>{t('In order to continue, please enter your email.')}</h3>
            <form onSubmit={verify}>
                {/* {error && <p>{error}</p>} */}
                <Input value={email} onChange={setEmail} label="Email" required type="email" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" />
                <Footer>
                    <Button variant={'primary'}>{t('Get PIN')}</Button>
                </Footer>
            </form>
        </Wrap>
    )
}

export default Verification
