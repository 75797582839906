import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Container } from '@/components'
import Input from '@/components/Input'
import Button from '@/components/Button'
import Tabs from '@/components/Tabs'
import Loading from '@/components/Loading'
import StringAppear from '@/components/StringAppear'
import Tooltip from 'react-tooltip'
import { toastError, toastSuccess } from '@toast'
import { get, post } from '@api'
import { useStore } from '@hooks'
import { ReactComponent as IconSend } from '@/assets/send.svg'
import { ReactComponent as IconCopy } from '@/assets/copy.svg'
import { ReactComponent as IconConnect } from '@/assets/connect.svg'
import {Wrap, Box, EarningsWrap, StatisticsWrap, AffiliateBox, AssetEarnings} from './styled'
import Table from './Table'

const Affiliate = () => {
    const [affiliates, setAffiliates] = useState({})
    const [email, setEmail] = useState('')
    const [becomeEmail, setBecomeEmail] = useState('')
    const [loading, setLoading] = useState(true)
    const [earningsTab, setEarningsTab] = useState(0)
    const [affTypeTab, setAffTypeTab] = useState(0)
    const [assetTab, setAssetTab] = useState(0)

    const store = useStore()
    const { t } = useTranslation()

    useEffect(() => {
        (async () => {
            await getAffiliates()
        })()
    }, [])

    const getAffiliates = async () => {
        const result = await get({ path: 'user/affiliates', token: store.tokens.token })
        if (result) {
            setAffiliates(result)
        }
        if (result.affiliated_by) {
            setBecomeEmail(result.affiliated_by)
        }
        setLoading(false)
    }

    const handleInviteSubmit = async (e) => {
        e.preventDefault()
        try {
            const body = {
                email,
            }
            const result = await post({ path: 'user/affiliates/invite', body, token: store.tokens.token })
            if (result.errors) {
                if (typeof result.errors === 'string') {
                    toastError(t(result.errors))
                    return
                }
                toastError(`${t('There was an error while inviting')} ${email}`)
                return
            }
            setEmail('')
            toastSuccess(t('Success. Affiliate was invited.'))
        } catch (err) {
            toastError(t('There was an error while inviting'))
        }
    }

    const handleBecomeSubmit = async (e) => {
        e.preventDefault()
        try {
            const body = {
                email: becomeEmail,
            }
            const result = await post({ path: 'user/affiliates/set', body, token: store.tokens.token })
            if (result.errors) {
                if (typeof result.errors === 'string') {
                    toastError(t(result.errors))
                    return
                }
                toastError(`${t('There was an error while assigning')} ${becomeEmail}`)
                return
            }
            toastSuccess(t('Success. Affiliate code created.'))
            await getAffiliates()
        } catch (err) {
            toastError(t('There was an error while assigning email'))
        }
    }

    const handleWithdraw = async () => {
        try {
            const result = await post({ path: 'user/affiliates/payout', token: store.tokens.token })
            if (result.errors) {
                if (typeof result.errors === 'string') {
                    toastError(t(result.errors))
                    return
                }
                toastError(t('There was an error while withdrawing'))
                return
            }
            toastSuccess(t('Successfully withdrew'))
            await getAffiliates()
        } catch (err) {
            toastError(t('There was an error while withdrawing'))
        }
    }

    const handleCopy = (text = '') => {
        navigator.clipboard.writeText(text)
        toastSuccess(t('Copied to clipboard'))
    }

    const renderBNB = () => {
        return <AssetEarnings>
            <p>{t('Total earnings')}</p>
            <p
                data-tip={t('Min Withdraw amount is 0.01 BNB')}
                data-place={'bottom'}
            >
                {(Number(affiliates?.total_earnings_first) + Number(affiliates?.total_earnings_second) - Number(affiliates?.already_payed)).toFixed(8)}
                <span>BNB</span>
                <Tooltip />
            </p>
            <Button
                variant="primary"
                disabled={(Number(affiliates?.total_earnings_first) + Number(affiliates?.total_earnings_second) - Number(affiliates?.already_payed)) < 0.01}
                onClick={handleWithdraw}
            >
                {t('Withdraw')}
            </Button>
            <p>{t('Lifetime payout')}: <b>{affiliates.already_payed}</b></p>
            <Tabs dark items={[`${t('Level')} 1 (8%)`, `${t('Level')} 2 (4%)`]} active={earningsTab} setActive={setEarningsTab} />
            {earningsTab === 0 ? (
                <StatisticsWrap>
                    <div>
                        <p>{t('Affiliates')}</p>
                        <p>{affiliates.affiliates ? Object.values(affiliates.affiliates).length : 0}</p>
                    </div>
                    <div>
                        <p>{t('Orders')}</p>
                        <p>{affiliates.total_orders_first}</p>
                    </div>
                    <div>
                        <p>{t('Earnings')}</p>
                        <p>{affiliates.total_earnings_first}</p>
                    </div>
                </StatisticsWrap>
            ) : (
                <StatisticsWrap>
                    <div>
                        <p>{t('Affiliates')}</p>
                        <p>{affiliates.affiliates ? Object.values(affiliates.affiliates).reduce((acc, val) => acc + val.children_count, 0) : 0}</p>
                    </div>
                    <div>
                        <p>{t('Orders')}</p>
                        <p>{affiliates.total_orders_second}</p>
                    </div>
                    <div>
                        <p>{t('Earnings')}</p>
                        <p>{affiliates.total_earnings_second}</p>
                    </div>
                </StatisticsWrap>
            )}
        </AssetEarnings>
    }

    const renderTOP =  () => {
        return <AssetEarnings>
            <p>{t('Total earnings')}</p>
            <p
                data-tip={t('Min Withdraw amount is 0.01 BNB')}
                data-place={'bottom'}
            >
                {affiliates.ico.total.toFixed(2)}
                <span>TOP</span>
            </p>

            <Tabs dark items={[`${t('Level')} 1 (8%)`, `${t('Level')} 2 (4%)`]} active={earningsTab} setActive={setEarningsTab} />
            {earningsTab === 0 ? (
                <StatisticsWrap>
                    <div>
                        <p>{t('Affiliates')}</p>
                        <p>{affiliates.affiliates ? Object.values(affiliates.affiliates).length : 0}</p>
                    </div>
                    <div>
                        <p>{t('Orders')}</p>
                        <p>{affiliates.ico[1].orders}</p>
                    </div>
                    <div>
                        <p>{t('Earnings')}</p>
                        <p>{affiliates.ico[1].total?.toFixed(2)}</p>
                    </div>
                </StatisticsWrap>
            ) : (
                <StatisticsWrap>
                    <div>
                        <p>{t('Affiliates')}</p>
                        <p>{affiliates.affiliates ? Object.values(affiliates.affiliates).reduce((acc, val) => acc + val.children_count, 0) : 0}</p>
                    </div>
                    <div>
                        <p>{t('Orders')}</p>
                        <p>{affiliates.ico[2].orders}</p>
                    </div>
                    <div>
                        <p>{t('Earnings')}</p>
                        <p>{affiliates.ico[2].total?.toFixed(2)}</p>
                    </div>
                </StatisticsWrap>
            )}
        </AssetEarnings>
    }

    return (
        <Wrap>
            <Container width={1020}>
                <Loading isLoading={loading} col={1}>
                    <h1>
                        <StringAppear string="Affiliate Program" />
                    </h1>
                    <Grid colExact="minmax(0, 2fr) minmax(0, 1fr)" align="stretch" mobileGap={20}>
                        <Box>
                            <Tabs align="left" dark items={[t('Invite Affiliates'), t('Become Affiliate')]} active={affTypeTab} setActive={setAffTypeTab} />
                            {affTypeTab === 0 ? (
                                <>
                                    <h3>{t('Share your affiliate link or send email invitation')}</h3>
                                    <Input
                                        variant="boxed"
                                        value={`https://tiptop.io/a/${affiliates.affiliate_code}`}
                                        disabled
                                        label={t('Share your link to Homepage')}
                                        button={<Button variant="primary" onClick={() => handleCopy(`https://tiptop.io/a/${affiliates.affiliate_code}`)}><IconCopy /> {t('Copy')}</Button>}
                                    />
                                    <Input
                                        variant="boxed"
                                        value={`https://m.tiptop.io/a/${affiliates.affiliate_code}`}
                                        disabled
                                        label={t('Share your link to Marketplace')}
                                        button={<Button variant="primary" onClick={() => handleCopy(`https://m.tiptop.io/a/${affiliates.affiliate_code}`)}><IconCopy /> {t('Copy')}</Button>}
                                    />
                                    <Input
                                        variant="boxed"
                                        value={`https://m.tiptop.io/as/${affiliates.affiliate_code}`}
                                        disabled
                                        label={t('Share your link to Registration page')}
                                        button={<Button variant="primary" onClick={() => handleCopy(`https://m.tiptop.io/as/${affiliates.affiliate_code}`)}><IconCopy /> {t('Copy')}</Button>}
                                    />
                                    <form onSubmit={handleInviteSubmit}>
                                        <Input
                                            variant="boxed"
                                            value={email}
                                            onChange={setEmail}
                                            label={t('Invite by email')}
                                            placeholder={t('Email address')}
                                            required
                                            type="email"
                                            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                            button={<Button variant="primary"><IconSend /> {t('Send')}</Button>}
                                        />
                                    </form>
                                </>) : (
                                <>
                                    <p>{t('Attention! You can connect to a lead user only once. It is not possible to connect or change the lead user if you have affiliates.')}</p>
                                    <form onSubmit={handleBecomeSubmit}>
                                        <Input
                                            variant="boxed"
                                            disabled={affiliates.affiliated_by}
                                            value={becomeEmail}
                                            onChange={setBecomeEmail}
                                            label={t("The lead user's email address")}
                                            placeholder={t('Email address')}
                                            required
                                            type="email"
                                            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                            button={!affiliates.affiliated_by && <Button variant="primary"><IconConnect /> {t('Connect')}</Button>}
                                        />
                                    </form>
                                </>
                            )}
                        </Box>
                        {/* Lvl 1 visi affiliate
                        Lvl 2 visu affiliate vaikai */}
                        <EarningsWrap>
                            <Tabs align="left" dark items={['BNB', 'TOP']} active={assetTab} setActive={setAssetTab} />
                            {assetTab === 0 ? renderBNB() : renderTOP() }
                        </EarningsWrap>
                    </Grid>
                    {affiliates.affiliates && <AffiliateBox>
                        <Table affiliates={affiliates.affiliates} />
                    </AffiliateBox>}
                </Loading>
            </Container>
        </Wrap>
    )
}

export default Affiliate
