import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ImgTransfer } from '../../../assets/ico-transfer.svg'
import { ActionsContent } from '../index'

const Actions = ({ showTransfer }) => {
    const { t } = useTranslation()
    return (
        <ActionsContent foreverAlone>
            <div onClick={showTransfer}>
                <ImgTransfer />
                {t('Transfer')}
            </div>
        </ActionsContent>
    )
}

export default Actions
