import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Container } from '../components'
import SubscribeForm from '../components/SubscribeForm'
import VisionLinesBg from '../assets/vision_lines_bg.png'

const SubscribeStyles = styled.div`
    margin: 0 20px;
    /* padding: 20px; */
    & > div {
        background-color: #19323C;
        text-align: center;
        padding: 80px 20px;
        border-radius: 20px;
        box-shadow: 0px 20px 40px rgba(0, 7, 10, 0.21);
        @media(max-width:1024px){
            padding: 40px 20px;
        }
    h2 {
        @media(max-width:1024px){
            font-size: 32px;
        }
    }
    p {
        margin: 10px auto;
        max-width: 920px;
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 60px;
        line-height: 36px;
        color: var(--subtext);
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 24px;
            margin-bottom:20px;
        }
    }
}
`

const Box = styled.div`
   background-image: url(${VisionLinesBg});
   background-size: 100% 100%;
   background-repeat: no-repeat;
   background-position: center top;
`

const Subscribe = () => {
    const { t } = useTranslation()

    return (
        <Box>
            <SubscribeStyles>
                <Container id='subscribe'>
                    <h2>{t('Subscribe to TipTop Newsletter')}</h2>
                    <p>{t('Join our growing community of fitness junkies and crypto enthusiasts!')}</p>
                    <SubscribeForm buttonText={t('Subscribe')} />
                </Container>
            </SubscribeStyles>
        </Box>
    )
}

export default Subscribe
