import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useStore } from '@/utils/hooks'
import { formatAmount } from '@/utils/amount'
import Table from '@/components/Table'
import { SimpleLink } from '@/components'
import { get } from '@api'
import { CapitalizeFirstLetter } from '@/utils'
import { Layout } from './'

const AssetsMap = {
    'binance': 'bnb',
}



const UpdateInterval = 5000

const Transactions = () => {
    const store = useStore()
    const { t } = useTranslation()

    const [transactions, setTransactions] = useState({})
    const [page, setPage] = useState(1)

    const trimMessage = (message) => {
        if (message === null) {
            return ''
        }

        const start = message.substring(0, 3)
        const length = message.length
        const end = message.substring(length - 3, length)
        return `${start}...${end}`
    }

    const resolveTypes = (type) => {
        return CapitalizeFirstLetter(type.toLowerCase().replaceAll('_', ' '))
    }

    const columns = [
        {
            name: ' ',
            cell: i => <img
                height={i.asset === 'nft' ? 50 : 20}
                src={i.asset === 'nft' ? i.shoe.image : store.settings.assets?.find(asset => asset.ticker.toUpperCase() === resolveAsset(i.asset))?.image}
                alt={resolveAsset(i.asset)}
            />,
            grow: 0,
            center: true,
        },
        {
            name: t('Asset'),
            cell: i => i.asset?.toUpperCase(),
            grow: 0,
        },
        {
            name: t('Amount'),
            cell: i => formatAmount(i.amount),
        },
        {
            name: t('Type'),
            cell: i => resolveTypes(i.type)
        },
        {
            name: t('Status'),
            cell: i => i.status?.toLowerCase().replace('_', ' '),
            sortable: true,
            sortFunction: (a, b) => a.status > b.status ? -1 : 1
        },
        {
            name: t('Link'),
            cell: i => i.status_message && <SimpleLink href={`https://bscscan.com/tx/${i.status_message}`}>{trimMessage(i.status_message)}</SimpleLink>,
            grow: 0,
        },
        {
            name: t('Date'),
            cell: i => i.date,
        }
    ]

    useEffect(() => {
        let timer
        (async () => {
            const result = await getTransactions()
            if ('transfers' in result) {
                setTransactions(result)
                const pendingTransactionsCount = result.transfers?.reduce((acc, val) => val.status !== 'COMPLETED' ? 1 + acc : acc, 0)
                if (!timer && pendingTransactionsCount) {
                    timer = setInterval(async () => {
                        const intervalResult = await getTransactions()
                        const newPendingTransactionsCount = intervalResult.transfers?.reduce((acc, val) => val.status !== 'COMPLETED' ? 1 + acc : acc, 0)
                        setTransactions(intervalResult)
                        if (newPendingTransactionsCount !== pendingTransactionsCount) {
                            await store.initUser(true)
                            if (newPendingTransactionsCount === 0) {
                                clearInterval(timer)
                                store.set('pingTransactions', false)
                            }
                        }
                    }, UpdateInterval)
                }
            }
        })()
        return () => clearInterval(timer)
    }, [store.pingTransactions, page])

    const getTransactions = async (tablePage) => {
        const result = await get({ path: `v2/user/transfers/${tablePage || page}`, token: store.tokens.token })
        setTransactions(result)
        return result
    }

    const resolveAsset = asset => {
        if (asset in AssetsMap) {
            return AssetsMap[asset].toUpperCase()
        }

        return asset.toUpperCase()
    }


    return (
        <Layout>
            <Wrap>
                <h3>{t('Transactions')}</h3>
                <Table
                    data={transactions?.transfers || []}
                    columns={columns}
                    onChangePage={setPage}
                    paginationTotalRows={transactions?.total}
                    paginationServer
                    responsive
                />
            </Wrap>
        </Layout>

    )

}


const Wrap = styled.div`
    background-color: var(--subbg);
    border-radius: 10px;
    padding: 25px;
    flex: 1;
    width: 100%;
    a {
        color: var(--special);
    }
    h3 {
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
    }
    & > div {
        width: 100%;
    }
    @media (max-width: 767px) {
        padding: 25px 10px;
        margin-right: -5px;
        h3 {
            padding: 0 15px;
        }
    }
`


export default observer(Transactions)
