import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useStore } from '@/utils/hooks'
import { SimpleLink, Flex } from '@/components'
import { ReactComponent as Logo } from '@/assets/logo.svg'
import { ReactComponent as ImgInventory } from '@/assets/inventory.svg'
import { ReactComponent as ImgMarketplace } from '@/assets/marketplace.svg'
import { ReactComponent as ImgWallet } from '@/assets/wallet.svg'
import { ReactComponent as ImgLogout } from '@/assets/logout.svg'
import { ReactComponent as ImgAffiliate } from '@/assets/ico-affiliate.svg'
import Button from '../Button'
import LinksModal from '../LinksModal'
// import LanguageSelect from './LanguageSelect'
import LanguageSelect from './LanguageSelect'
//import ConnectorButton from '../../pages/Wallets/WalletsConnector/components/WalletConnectButton'

const MobileNavbarWrap = styled.section`
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(15px);
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    height: 60px;
    & > a > svg {
        width: auto;
        height: 30px;
    }
`

const MobWrap = styled.div`
    overflow-x: hidden;
`

const Nav = styled.div`
    display: flex;
    
    a {
        background: #FFFFFF1A;
        border-radius: 10px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        
        svg {
            width: 25px;
            height: auto;
        }
    }
`

const Logout = styled(ImgLogout)`
    height: 40px;
    margin-left: 10px;
`

const UnauthorizedWrap = styled(Flex)`
    a + a {
        margin-left: 10px;
    }
    button {
        margin-left: 10px;
    }
`

// const StyledConnectorButton = styled.div`
//     margin-left:10px;
// `

const Navbar = ({ location, isOpen, setIsOpen }) => {
    const store = useStore()
    const { t } = useTranslation()

    return (
        <><MobWrap>
            <MobileNavbarWrap>
                <a href="https://tiptop.io">
                    <Logo />
                </a>
                {!store.isLoading && <>
                    {store.user ? (
                        <Nav>
                            <Link to={'/wallet'}>
                                <ImgWallet />
                            </Link>
                            <Link to={'/affiliate'}>
                                <ImgAffiliate />
                            </Link>
                            {location.pathname.indexOf('inventory') === -1 ?
                                <Link to={'/inventory'} onClick={() => window.scrollTo(0, 0)}>
                                    <ImgInventory />
                                </Link>
                                :
                                <Link to={'/marketplace'} onClick={() => window.scrollTo(0, 0)}>
                                    <ImgMarketplace />
                                </Link>}
                            <Logout onClick={store.logout} />
                            {/*<StyledConnectorButton>*/}
                            {/*    <div>*/}
                            {/*        <ConnectorButton/>*/}
                            {/*    </div>*/}
                            {/*</StyledConnectorButton>*/}
                        </Nav>
                    ) : (
                        <UnauthorizedWrap>
                            <Link to='/login'><Button variant='primary'>{t('Login')}</Button></Link>
                            <Button onClick={() => setIsOpen(!isOpen)}>{t('Download App')}</Button>
                        </UnauthorizedWrap>
                    )}
                    <LanguageSelect mob />
                </>}
            </MobileNavbarWrap>
        </MobWrap><LinksModal isOpen={isOpen} setIsOpen={setIsOpen} /></>
    )
}

export default observer(Navbar)
