import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from '@/components/Button'
import CreateWallet from './WalletModal/CreateWallet'
import ImportWallet from './WalletModal/ImportWallet'

const WalletModal = ({ onSuccess }) => {
    const { t } = useTranslation()

    const [showCreateWallet, setShowCreateWallet] = useState(false)
    const [showImportWallet, setShowImportWallet] = useState(false)

    const renderContent = () => {
        if (showCreateWallet) {
            return <CreateWallet onSuccess={onSuccess} />
        } else if (showImportWallet) {
            return <ImportWallet onSuccess={onSuccess} />
        } else {
            return <>
                <Button variant={'primary'} onClick={() => setShowCreateWallet(true)}>{t('Create a new wallet')}</Button>
                <Button variant={'secondary'} onClick={() => setShowImportWallet(true)}>{t('Import wallet')}</Button>
            </>
        }
    }

    return (
        <Wrap>
            <h3>{t('Add wallet')}</h3>
            <Content>
                {renderContent()}
            </Content>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 500px;
    margin: auto;
    @media (max-width: 767px) {
        width: calc(100vw - 100px);
    }
    
    h3 {
        font-size: 20px;
        @media (max-width: 767px) {
            font-size: 18px;
        }
    }
    p {
        margin-top: 20px;
        font-size: 16px;
    }
`

export const Content = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    
    p {
        font-size: 13px;
        margin: 0 0 10px 0;
    }
    
    button {
        margin-bottom: 10px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
`

export const Footer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
`

export default WalletModal
