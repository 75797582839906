import React from 'react'
import { observer } from 'mobx-react-lite'
import { Grid, Flex } from '@/components'
import { WaterImages } from '../../../components/NftImages'
import ItemFooter from '../components/ItemFooter'
import { ItemPreview } from '../styled'
import Badges from '../components/Badges'
import { QuantityWrap } from './Sock'
import Wrap from './Wrap'

const Water = ({ item, handleBuy }) => {
    return (
        <Wrap key={item.id}>
            <ItemPreview onClick={() => handleBuy && handleBuy(item, 'water')} clickable={!!handleBuy}>
                <Flex justify="space-between">
                    <h4>{item.title}</h4>
                    <Badges badges={[`${item.capacity / 1000} l`]} />
                </Flex>
                <img
                    src={WaterImages[item.capacity]}
                    alt={item.title}
                />
                <Grid col={4} disableAt={1}>
                    {item.code && <div>#{item.code}</div>}
                </Grid>
                {item.quantity && (<QuantityWrap>{item.quantity}x</QuantityWrap>)}
            </ItemPreview>
            {handleBuy && <ItemFooter item={item} type={'water'} handleBuy={handleBuy} />}
        </Wrap>
    )
}

export default observer(Water)
