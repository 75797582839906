import React from 'react'
import { useStore } from '@/utils/hooks'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Accordion from '@/components/Accordion'
import { LockedItem } from './styled'

const LockedSidebar = () => {
    const store = useStore()
    // get TOP info
    const item = store.settings.assets.find(i => i.ticker.toLowerCase() === 'top')

    return (
        <Wrap>
            <Accordion title={<h3>Locked assets</h3>}>
                {store.user.vaults.length ?
                    store.user.vaults.map(data => (
                        <LockedItem colExact="100px 1fr" colXsExact="60px 1fr" key={data.ticker + data.ends + data.amount} disableAt={1}>
                            <p><img src={item.image} alt={item.ticker} /> {item.ticker}</p>
                            <p>{Number(data.amount).toFixed(0)} (ends: {data.ends})</p>
                        </LockedItem>
                    )) : (
                        <p>You have no locked assets</p>
                    )}
            </Accordion>
        </Wrap>
    )
}

const Wrap = styled.div`
    p {
        font-size: 14px;
    }
    & > div > div:first-child {
        cursor: pointer;
    }
    & > div > div:last-child {
        padding: 0;
        overflow-y: scroll;
    }
`

export default observer(LockedSidebar)
