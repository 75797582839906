import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { ReactComponent as ImgKarma } from '@/assets/karma.svg'
import { ReactComponent as ImgSpeed } from '@/assets/speed.svg'
import { ReactComponent as ImgPerformance } from '@/assets/performance.svg'

const Performance = ({ item, showTitle }) => {
    const renderPerformanceIcon = () => {
        switch (item.type) {
            case 'performance':
                return <ImgPerformance />
            case 'karma':
                return <ImgKarma />
            case 'stamina':
                return <ImgSpeed />
        }
    }

    return (
        <Wrapper>
            {renderPerformanceIcon()}
            +{item.value}% {showTitle && item.type}
        </Wrapper>
    )
}


const Wrapper = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    svg {
        margin-right: 5px;
    }
`

export default observer(Performance)
