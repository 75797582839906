import i18next from 'i18next'

export const CapitalizeFirstLetter = (str) => str ? str.charAt(0).toUpperCase() + str.slice(1) : ''

export const t = (string) => {
    const translated = i18next.t(string)

    if (typeof translated !== 'string' || translated.length === 0) {
        return string
    }

    return translated
}
