import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Modal from '@/components/Modal'
import Button from '@/components/Button'
import { Content } from '../BuyModal'
import Footer from './Footer'

import { Wrap } from '../BuyModal'

const SuccessFoxpay = ({ item: { image, item_name }, setOpen, open }) => {
    const { t } = useTranslation()

    return (
        <Modal open={open} setOpen={setOpen} ariaHideApp={false}>
            <Wrap>
                <h3>{item_name}</h3>
                <p>{t('Thank you, your item was purchaced succesfully!')}</p>
                <Content>
                    <img src={image} alt={item_name} height="200px" />
                </Content>
                <Footer>
                    <Button onClick={setOpen}>{t('Close')}</Button>
                    <Link to="/inventory">
                        <Button variant={'primary'} onClick={setOpen}>{t('Inventory')}</Button>
                    </Link>
                </Footer>
            </Wrap>
        </Modal>
    )
}

export default SuccessFoxpay
