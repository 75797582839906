import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Label } from './index'
import { ReactComponent as ImgSwap } from '@/assets/ico-swap.svg'
import { ReactComponent as ImgDropdown } from '@/assets/ico-dropdown.svg'
import { shortenAddress } from '@/utils/wallet'

const Direction = ({ setDirection, setReceiver, receiver, sender }) => {
    const [showReceiver, setShowReceiver] = useState(false)

    const { t } = useTranslation()

    const swapDirection = () => {
        setShowReceiver(false)
        setReceiver(null)

        if (sender === 'wallet') {
            setDirection('spending')
        } else {
            setDirection('wallet')
        }
    }

    const onChange = (e) => {
        setReceiver(e.target.value)
    }

    const renderReceiver = () => {
        return <Receiver>
            <SelectReceiver onClick={() => setShowReceiver(!showReceiver)}>
                {receiver !== null && receiver.length > 0 ? t('External wallet') : t('Spending account')}
                <ImgDropdown />
                {receiver !== null && receiver.length > 0 && !showReceiver &&
                    <div>{shortenAddress(receiver)}</div>
                }
            </SelectReceiver>
            {showReceiver &&
                <div>
                    <input value={receiver} type={'text'} onChange={onChange} placeholder={t('External wallet address')} />
                </div>
            }
        </Receiver>
    }

    return <Wrap>
        <Swap onClick={swapDirection}>
            <ImgSwap />
        </Swap>
        <div>
            <Label>{t('From')}</Label>
            <Value>{sender === 'wallet' ? t('Wallet') : t('Spending account')}</Value>
        </div>
        <div>
            <Label>{t('To')}</Label>
            <Value>{sender !== 'wallet' ? t('Wallet') : renderReceiver()}</Value>
        </div>
    </Wrap>
}

const Wrap = styled.div`
    border-radius: 10px;
    background-color: #0D2631;
    padding: 20px;
    margin-bottom: 15px;
    position: relative;
    padding: 0;
    
    & > div {
        display: flex;
        padding: 20px;
        border-bottom: 1px solid var(--border);
        align-items: center;
        
        &:last-child {
            border-bottom: 0;
        }
        
        strong {
            width: 60px;
        }
        
        @media (max-width: 800px) {
            strong {
                width: auto;
                margin-right: 10px;
            }
        }
    }
`

const Receiver = styled.div`
    input {
        border: 0;
        background: #19323C;
        border-radius: 10px;
        width: 100%;
        padding: 5px 10px;
        font-size: 14px;
        margin-top: 10px;
        color: #fff;
        
        &:focus {
            outline: none;
        }
    }
`

const SelectReceiver = styled.div`
    cursor: pointer;
    
    & > div {
        font-size: 12px;
        opacity: 0.5;
    }
    
    svg {
        margin-left: 6px;
    }
    
    &:hover {
        text-decoration: underline;
    }
`

const Value = styled.div`
    flex: 1;
`

const Swap = styled.div`
    position: absolute;
    background-color: #FF5F69;
    top: 37px;
    right: 5%;
    border-radius: 100px;
    width: 48px;
    height: 48px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
        opacity: 0.7; 
    }
    
    svg {
        transform: scale(2);
    }
`

export default Direction
