import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { t } from '@/utils'
import Input from '@/components/Input'
import Button from '@/components/Button'
import Checkbox from '@/components/Checkbox'
import { toastError, toastSuccess } from '@toast'

const FormStyles = styled.form`
    input {
        max-width:520px;
        margin: 0 auto;
        border-radius: 8px;
        background:#D9D9D9;
        font-size: 18px;
    }
    button {
        margin-top: 20px;
    }
`

const SubscribeForm = ({ buttonText }) => {
    const [checked, setChecked] = useState(false)
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const { t } = useTranslation()

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            setIsLoading(true)
            const result = await fetch('https://sfjtzvvdks.tiptop.io/subscribe', {
                headers: {
                    'Content-Type': 'application/json',
                    // 'accept': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    email
                })
            }).then((res) => res.json())
            // window.scrollTo(0, 0)
            if (result?.success) {
                toastSuccess(t('Subscribed successfully!'))
            } else {
                toastError(t('Subscribing error. Check your email address format.'))
            }
        } catch (err) {
            console.error('Error while submitting form: ', err)
        } finally {
            setIsLoading(false)
        }
        setTimeout(() => {
            setEmail('')
        }, 4000)
    }
    return (
        <FormStyles id='subscribe' onSubmit={handleSubmit}>
            <Input value={email} onChange={setEmail} type="email" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder={t('Enter email address')} required />
            <Checkbox onChange={() => setChecked(!checked)} label={t('I accept to the privacy policy and agree to receive updates from TipTop.')} />
            <Button variant="primary" isLoading={isLoading} disabled={!checked}>{buttonText}</Button>
        </FormStyles>
    )
}

export default SubscribeForm
