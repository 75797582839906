import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ImgLoader } from '../assets/loader.svg'
import { ReactComponent as Logo } from '../assets/logo.svg'

const FullPageLoading = () => (
    <Wrap>
        <div>
            <ImgLoader />
        </div>
    </Wrap>
)

const NavLoading = () => (
    <NavWrap>
        <Logo />
    </NavWrap>
)

const Wrap = styled.div`
    width: 100%;
    padding-top: 300px;
    padding-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
`
const NavWrap = styled.div`
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    padding: 20px 40px;
    display: flex;
    align-items: center;
    opacity: 0.5;
`

export { FullPageLoading, NavLoading }
