import { useRef, useState, useEffect, useContext } from 'react'
import { StoreContext } from '../App'

export const useScrollObserver = (options) => {
    const wrapperRef = useRef(null)
    let showCount = 0
    const [isVisible, setIsVisible] = useState(false)

    const callbackFn = (entries) => {
        const [ entry ] = entries
        if (options?.playOnce) {
            if(showCount > 1) {
                setIsVisible(true)
                return
            }
            showCount++
        }
        setIsVisible(entry.isIntersecting)
    }

    useEffect(() => {
        const observer = new IntersectionObserver(callbackFn)
        const ref = wrapperRef.current
        if(ref) observer.observe(ref)

        return () => {
            if(ref) observer.unobserve(ref)
        }
    }, [wrapperRef])

    return [wrapperRef, isVisible]
}

export const useStore = () => {
    const store = useContext(StoreContext)
    return store
}