import React from 'react'
import styled from 'styled-components'

const CheckboxStyles = styled.div`
    padding: 20px 0;
    input {
        margin-right: 5px;
    }
`

const Checkbox = ({ label, value, onChange }) => {
    return (
        <CheckboxStyles>
            <label>
                <input type="checkbox" checked={value} onChange={onChange} />
                {label}
            </label>
        </CheckboxStyles>

    )
}

export default Checkbox