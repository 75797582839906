import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from '@/components/Button'
import { ReactComponent as ImgAlert } from '@/assets/alert-icon.svg'
import { Footer } from '../../WalletModal'

const Create = ({ seed, showConfirm }) => {
    const { t } = useTranslation()
    return <>
        <p>
            {t('You will be generated a Secret Recovery Phrase. Write it down and make sure you remember it. This phrase will help you back up and recover your wallet if needed.')}
        </p>
        <Alert>
            <strong><ImgAlert />{t('Safety information')}</strong>
            <p>
                {t('We do not track or store your information. Please make sure to remember your Secret Recovery Phrase. Do not share your phrase with anyone.')}
            </p>
        </Alert>
        <Seed>
            {seed.split(' ').map((phrase, index) => <Phrase key={index}><span>{index + 1}&nbsp;</span>{phrase}</Phrase>)}
        </Seed>
        <Footer>
            <Button variant={'primary'} onClick={showConfirm} >{t('I have written down')}</Button>
        </Footer>
    </>
}

const Alert = styled.div`
    background: var(--dbg);
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    
    p {
        margin-top: 5px;
        margin-bottom: 0;
    }
    strong {
        color: #35A6B6;
        font-weight: 600;
        display: flex;
        
        svg {
            margin-right: 7px;
        }
    }
`

const Seed = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    column-count: 3;
    column-gap: 15px;
        
    @media (max-width: 767px) {
      column-count: 2;
    }
`

const Phrase = styled.li`
    background: var(--dbg);
    border-radius: 10px;
    padding: 5px 10px;
    margin-bottom: 15px;
    
    span {
        color: #35A6B6;
        width: 20px;
        display: inline-block;
    }
`

export default Create
