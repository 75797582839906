import Button from '@/components/Button'
import { useTranslation } from 'react-i18next'
import Footer from './Footer'
import { Price, PriceWrap } from '../../components/Price'
import { Content } from '../BuyModal'

const Error = ({ item, title, message, setOpen, discount }) => {
    const { t } = useTranslation()
    return (
        <>
            <h3>{title}</h3>
            <p>{message}</p>

            <Content>
                <PriceWrap>
                    <Price item={item} discount={discount} />
                    {item.type}
                </PriceWrap>
            </Content>
            <Footer fullWidth>
                <Button variant='primary' onClick={() => setOpen(false)}>{t('Cancel purchase')}</Button>
            </Footer>
        </>
    )
}

export default Error
