import PinInput from 'react-pin-input'

const PIN = ({ onComplete, onChange, secret = false, length = 6 }) => (
    <PinInput
        length={length}
        initialValue="-"
        secret={secret}
        onChange={onChange}
        type="numeric"
        inputMode="number"
        style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${length}, minmax(0, 1fr))`,
            gridGap: 15,
            width: 'fit-content',
            margin: 'auto'
        }}
        inputStyle={{ border: 'none', borderRadius: 8, background: 'var(--subtext)', fontSize: 18, margin: 0 }}
        inputFocusStyle={{ background: 'var(--text)' }}
        onComplete={onComplete}
        autoSelect={true}
        regexCriteria="[^@\s]+@[^@\s]+\.[^@\s]+"
    />
)

export default PIN