import styled from 'styled-components'
import Progress from '@/components/Progress'

export const ProgressBar = ({ children, value, max, color }) => {
    return (
        <StyledProgressBar>
            <Header>
                <div>{children}</div>
                <div>{value}<b>/ {max}</b></div>
            </Header>
            <Progress
                value={value}
                max={max}
                color={color}
            />
        </StyledProgressBar>
    )
}

const StyledProgressBar = styled.div`
    progress {
        width: 100%;
        @media (max-width: 767px) {
            height: 6px;
            top: -8px;
            position: relative;
        }
    }
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    b {
        margin-left: 2.5px;
        color: var(--subtext);
    }
    @media (max-width: 767px) {
        font-size: 12px;
    }
    div {
        display: flex;
        align-items: center;
        
        b {
            font-weight: 600;
        }
        svg {
            margin: 0 10px 0 0;
        }
    }
`
