import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Container, SimpleLink } from '@/components'
import InstructionsImg from '@/assets/join-image.jpg'
// import ImgAppleStore from '../assets/app_store.svg'
import { ReactComponent as ImgGooglePlay } from '@/assets/google_play.svg'
import { ReactComponent as ImgApk } from '@/assets/apk.svg'
import { ReactComponent as AppStore } from '@/assets/app_store.svg'

const JoinInstructions = () => {
    const { t } = useTranslation()
    return (
        <InstructionsContainer>
            <a name={'instructions'} id={'instructions'} />
            <InstructionsGrid col={2}>
                <LeftSide>
                    <img src={InstructionsImg} alt="" loading="lazy" />
                </LeftSide>
                <RightSide>
                    <h2>{t('Join TipTop in 3 Easy Steps')}</h2>
                    <ul>
                        <li>
                            <div>1</div>
                            <div>
                                <strong>{t('Download the TipTop App')}</strong>
                                <p>{t('You can find the app on Google Play or Apple Play Store')}</p>
                            </div>
                        </li>
                        <li>
                            <div>2</div>
                            <div>
                                <strong>{t('Register an Account')}</strong>
                                <p>{t('By registering, you agree with our Terms and Privacy Policy')}</p>
                            </div>
                        </li>
                        <li>
                            <div>3</div>
                            <div>
                                <strong>{t('Move to Earn')}</strong>
                                <p>{t('Put on the Demo Sneaker and give TipTop a try!')}</p>
                            </div>
                        </li>
                    </ul>

                    <Stores>
                        <SimpleLink href={'https://play.google.com/store/apps/details?id=com.tiptop.io'}>
                            <ImgGooglePlay />
                        </SimpleLink>
                        <SimpleLink href="https://tiptop.io/app.apk">
                            <ImgApk />
                        </SimpleLink>
                        <SimpleLink href="https://apps.apple.com/app/tiptop-sneakers/id6444293849">
                                <AppStore />
                            </SimpleLink>
                        {/*
                        <SimpleLink href={''}>
                            <img src={ImgAppleStore} alt="TipTop Apple Store" />
                        </SimpleLink> */}
                    </Stores>
                </RightSide>
            </InstructionsGrid>
        </InstructionsContainer>
    )
}

const Stores = styled.div`
    margin-top: 60px;
    svg {
        height: 50px;
    }
    @media (min-width: 500px) {
        a + a {
            margin-left: 20px;
        }
    }
    @media (max-width: 1300px) {
        margin-top: 30px;
    }
    @media (max-width: 500px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        svg {
            width: 100%;
        }
    }
`

const InstructionsContainer = styled(Container)`
    margin-bottom: 50px;
`

const InstructionsGrid = styled.div`
    display: flex;
    margin: 0 -20px;
    align-items: center;
    & > div {
        flex: 1;
        margin: 0 20px;
    }
    
    @media (max-width: 1024px) {
        & > :first-child {
            display: none
        }
    }
`

const LeftSide = styled.div`
    img {
        width: 100%;
        border-radius: 22px;
    }
`

const RightSide = styled.div`
    h2 {
        margin-bottom: 70px;
    }
    ul, li {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    ul {
        margin-top: 20px;
    }
    li {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        
        & > div:first-child {
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            width: 60px;
            margin-right: 45px;
        }
        & > div:last-child {
            flex: 1;
        }
        
        strong {
            font-size: 24px;
            font-weight: 500;
        }
        
        p {
            font-size: 20px;
            opacity: 0.5;
        }
    }
    
    @media (max-width: 1300px) {
        h2 {
            margin-bottom: 30px;
        }
        
        ul li {
            margin-bottom: 20px;
        }
    }
    
    @media (max-width: 767px) {
        li div:first-child {
            font-size: 20px;
            margin-right: 20px;
            width: 40px;
        }
        
        li {
            strong {
                font-size: 20px;
            }
            
            p {
                font-size: 16px;
            }
        }
    }
`

export default JoinInstructions
