import React, { useState,useMemo } from 'react'
import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'
import { Link, useLocation, NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useStore } from '@hooks'
// import { t } from '@/utils'
import Balances from '../Balances'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import Button from '../Button'
import MobNav from './MobNav'
import LanguageSelect from './LanguageSelect'
import { ReactComponent as ImgInventory } from '@/assets/inventory.svg'
import { ReactComponent as ImgMarketplace } from '@/assets/marketplace.svg'
import { ReactComponent as ImgLogout } from '@/assets/logout.svg'
import { ReactComponent as ImgAffiliate } from '@/assets/ico-affiliate.svg'
import { SimpleLink } from '..'
import LinksModal from '../LinksModal'
// import ConnectorButton from '../../pages/Wallets/WalletsConnector/components/WalletConnectButton'

const StyledNavbarWrap = styled.div`
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 40px;
    transition: all 0s ease-out;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(15px);
    @media (max-width: 1400px) {
        padding: 0 20px;
        & > nav > a:first-child > svg {
            height: 40px;
            width: auto;
        }
        button {
            font-size: 12px;
        }
    }
`

const StyledNavbar = styled.nav`
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > * + * {
        margin-left: 30px;
    }
    & > div:first-of-type > a {
        font-weight: 600;
        & + a {
            margin-left: 35px;
        }
    }
    & > div:last-of-type {
        display: flex;
        align-items: center;
        & > a + a {
            margin-left: 15px;
        }
    }
    @media (max-width: 1500px) {
        & > div:first-of-type > a {
            & + a {
                margin-left: 20px;
            }
        }
        & > div:last-of-type {
            & > a + a {
                margin-left: 5px;
            }
        }
        & > * + * {
            margin-left: 15px;
        }
    }
`

const StyledNav = styled.div`
    display: flex;
    a {
        margin-right: 20px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        &.active, &:hover {
            color: var(--special);
            svg path {
                fill: var(--special);
            }
        }
        svg {
           margin-right: 10px; 
        }
        @media (max-width: 1250px) {
            font-size: 14px;
        }
    }
    
`
// const StyledConnectorButton = styled.div`
//     margin-right:5px;
// `

const Logout = styled(ImgLogout)`
    cursor: pointer;
    margin-left: 20px;
`

const Navbar = () => {
    const store = useStore()
    const [isOpen, setIsOpen] = useState()
    const { t } = useTranslation()

    const balances = useMemo(() => {
        const result = []

        store.settings.assets?.forEach(asset => {
            if (asset.active) {
                result.push({
                    ...asset, balance: store.user?.wallet[asset.ticker.toLowerCase()]
                })
            }
        })

        return result
    }, [store.settings, store?.user])

    const location = useLocation()

    return window.innerWidth > 1164 ? (
        <><StyledNavbarWrap>
            <StyledNavbar>
                <a href="https://tiptop.io">
                    <Logo />
                </a>
                <div>
                    {store.user ? (
                        <>
                            <StyledNav>
                                <NavLink to="/affiliate" onClick={() => window.scrollTo(0, 0)}>
                                    <ImgAffiliate />
                                    {t('Affiliate Program')}
                                </NavLink>
                                <NavLink to="/inventory" onClick={() => window.scrollTo(0, 0)}>
                                    <ImgInventory />
                                    {t('Inventory')}
                                </NavLink>
                                <NavLink to="/marketplace" onClick={() => window.scrollTo(0, 0)}>
                                    <ImgMarketplace />
                                    {t('Marketplace')}
                                </NavLink>
                            </StyledNav>
                            {balances.length > 0 &&
                                <Link to={'/wallet'}>
                                    <Balances balances={balances} />
                                </Link>}
                        </>
                    ) : (
                        <HashLink onClick={() => window.scrollTo(0, 0)} to="/login">
                            <Button variant="primary">{t('Login')}</Button>
                        </HashLink>
                    )}
                    <Button style={{margin:'0 0 0 10px'}} onClick={() => setIsOpen(!isOpen)} variant="secondary">{t('Download App')}</Button>
                    <LanguageSelect />
                    {store.user && <Logout onClick={store.logout} />}
                </div>
            </StyledNavbar>
        </StyledNavbarWrap><LinksModal isOpen={isOpen} setIsOpen={setIsOpen} /></>
    ) : (
        <MobNav isOpen={isOpen} setIsOpen={setIsOpen} location={location} />
    )
}

export default observer(Navbar)
