import { makeAutoObservable } from 'mobx'
import { handleRefreshToken, get } from '@api'

const initialValues = {
    tokens: {
        token: localStorage.getItem('token'),
        refreshToken: ''
    },
    wallet: null,
    user: null,
    marketplace: [],
    settings: {},
    inventory: [],
}

const store = () => makeAutoObservable({
    ...initialValues,
    isLoading: true,
    prevPath: '',
    pingTransactions: false,
    set(property, value) {
        this[property] = value
    },
    async refreshToken() {
        const result = await handleRefreshToken(this.tokens?.refreshToken)
        this.tokens.refreshToken = result.refresh_token
    },
    async getWallet() {
        const wallet = await get({
            path: 'wallet/balance',
            token: this.tokens?.token
        })
        this.set('wallet', wallet)
    },
    async initUser(force = false) {
        const user = await get({
            path: 'user',
            token: this.tokens?.token
        })
        if (force || user.email) {
            this.set('user', user)
            await this.getWallet()
        } else {
            this.logout()
        }
    },
    async getAppSettings() {
        const settings = await get({
            path: 'configs'
        })
        const Settings = {
            ...settings,
            assets: settings.assets.filter(i => i.active),
            currencies: Object.entries(settings.currencies).filter(i => i[1].active).map(currency => ({ ...currency[1] })),
        }
        this.set('settings', Settings)
    },
    async getInventory() {
        const result = await get({ path: 'inventory', token: this.tokens.token })
        this.set('inventory', result)
    },
    updateWallet(wallet) {
        const user = this.user
        user.wallet_address = wallet.walletAddress
        this.set('user', user)
        this.set('wallet', wallet)
    },
    logout() {
        localStorage.removeItem('token')
        Object.entries(initialValues).map(val => this[val[0]] = val[1])
    }
}, {}, { autoBind: true })

export default store
