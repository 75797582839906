import styled from 'styled-components'

const TabsWrap = styled.div`
    display: grid;
    grid-template-columns: repeat(${({ tabs }) => tabs}, minmax(0, 1fr));
    width: fit-content;
    margin: ${({ align }) => align === 'left' ? 0 : '0 auto'};
    margin-bottom: 20px;
    overflow: visible;
    position: relative;
    z-index: 1;
    &::after {
        content:'';
        position: absolute;
        z-index: -1;
        border-radius: 5px;
        background-color: var(--special);
        top: 0;
        height: 100%;
        transition: left 0.3s;
        ${({ active, tabs }) => `
            left: ${100 / tabs * active}%;
            width: calc(100% / ${tabs});
        `}
    }
    & > button {
        font-weight: 600;
        cursor: pointer;
        appearance: none;
        border: none;
        padding: 5px 10px;
        border-radius: 5px;
        color: #ffffff80;
        background: none;
        text-transform: capitalize;
        &:nth-child(${({ active }) => active + 1}), &:hover {
            color: var(--text);
        }
    }
`

const Tabs = ({ items, active, setActive, dark, align }) => (
    <TabsWrap tabs={items.length} active={active} dark={dark} align={align}>
        {items.map((item, i) => (
            <button key={item} onClick={() => setActive(i)}>{item}</button>
        ))}
    </TabsWrap>
)

export default Tabs
