import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ImgFilter } from '@/assets/filter.svg'
import { ReactComponent as ImgSort } from '@/assets/sort.svg'
import Sort from './Sort'
import Filter from './Filter'

export const filters = {
    shoes: {
        rarity: {
            'common': 'Common',
            'uncommon': 'Uncommon',
            'rare': 'Rare',
            'epic': 'Epic',
        },
        type: {
            'tiptopper': 'Tiptopper',
            'jogger': 'Jogger',
            'walker': 'Walker',
            'runner': 'Runner',
        },
    },
    socks: {
        type: {
            0: 'Performance',
            1: 'Stamina',
            2: 'Karma',
        }
    },
}

export const sorters = {
    shoes: {
        sort: {
            'price_desc': 'Highest price',
            'price_asc': 'Lowest price',
            'latest': 'Latest',
        }
    },
    socks: {
        price: {
            'asc': 'Lowest price',
            'desc': 'Highest price',
        }
    }
}

const Filters = ({ type, sort, setSort, filter, setFilter }) => {
    const [filterVisible, setFilterVisible] = useState(false)
    const [sortVisible, setSortVisible] = useState(false)
    const ref = useRef(null)

    const { t } = useTranslation()

    useEffect(() => {
        setSort(null)
        setFilter(null)
    }, [type])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setFilterVisible(false)
                setSortVisible(false)
            }
        }
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [])

    const toggleFilter = () => {
        setSortVisible(false)
        setFilterVisible(!filterVisible)
    }

    const toggleSort = () => {
        setFilterVisible(false)
        setSortVisible(!sortVisible)
    }

    const onSortSelect = (key) => {
        setSort(key === sort ? '' : key)
        setSortVisible(false)
    }

    const onSetFilter = (filter) => {
        setFilter(filter)
        setFilterVisible(false)
    }

    const renderSelectedFilter = () => {
        if (filter === null) {
            return <></>
        }

        const result = []

        Object.keys(filter).forEach((key, value) => {
            if (key in filters[type]) {
                result.push(filters[type][key][filter[key]])
            }
        })

        if (result.length === 0) {
            return <></>
        }

        return <>: <span>{result.join(' ')}</span></>
    }

    const renderSelectedSort = () => {
        if (sort !== null) {
            const sortKey = Object.keys(sorters[type])[0]
            return <>:<span>{sorters[type][sortKey][sort]}</span></>
        }

        return <></>
    }

    return <Wrap>
        <Controls ref={ref}>
            {type in filters &&
                <Control active={filterVisible}>
                    <div onClick={toggleFilter}><ImgFilter /> {t('Filter')}{renderSelectedFilter()}</div>
                    {filterVisible &&
                        <Dropdown>
                            <Filter values={filters[type]} setFilter={onSetFilter} filter={filter} />
                        </Dropdown>
                    }
                </Control>
            }
            {type in sorters &&
                <Control active={sortVisible}>
                    <div onClick={toggleSort}><ImgSort /> {t('Sort by')}{renderSelectedSort()}</div>
                    {sortVisible &&
                        <Dropdown>
                            <Sort values={sorters[type]} onSelect={onSortSelect} selected={sort} />
                        </Dropdown>
                    }
                </Control>
            }
        </Controls>
    </Wrap>
}

export default Filters

const Wrap = styled.div`
    display: flex;
    justify-content: right;
    margin-bottom: 25px;
    
    @media (max-width: 767px) {
        justify-content: left;
        margin-bottom: 15px;
    }
`

const Dropdown = styled.div`
    position: absolute;
    right: 0;
    top: 34px;
    background: #19323C;
    border: 1px solid #19323C;
    z-index: 10;
    border-radius: 10px;
    box-shadow: 1px 5px 15px -5px rgba(0,0,0,0.55);

    @media (max-width: 767px) {
        right: auto;
        left: 0;
    }
`

const Controls = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Control = styled.div`
    position: relative;
    font-size: 13px;
    @media (max-width: 767px) {
        font-size: 12px;
    }
    span {
        font-size: 13px;
        margin-left: 3px;
        opacity: 0.7;
        
        @media (max-width: 767px) {
            font-size: 10px;
        }
    }

    > div:first-child {
        margin-left: 5px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        opacity: 0.8;
        border-radius: 10px;
        border-radius: 10px;
        transition: none;
            
        &:hover {
            opacity: 1;
        }
    }

    ${props => props.active &&
        css`
        > div:first-child {
            background: #19323C;
            opacity: 1;
        }
    `};

    svg {
        margin-right: 5px;
    }
`
