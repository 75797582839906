import {useEffect, useState} from 'react'
import styled from 'styled-components'
import { t } from '@/utils'
import Button from '@/components/Button'
import { ReactComponent as CardMastercard } from '@/assets/cards/mastercard.svg'
import { ReactComponent as CardVisa } from '@/assets/cards/visa.svg'
import Footer from './Footer'
import { AssetIco } from '../../components/AssetIco'
import { PriceWrap } from '../../components/Price'
import { useStore } from '../../../../utils/hooks'
import DiscountCode from './DiscountCode'
import {discountedPrice, formatAmount} from '../../../../utils/amount'

const ButtonCard = styled.button`
    width: 100%;
    outline: none;
    cursor: pointer;
    border: 1px solid var(--dbg);
    border-radius: 10px;
    color: var(--text);
    padding: 20px 20px 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${({ active }) => active ? 'var(--special)' : 'var(--dbg)'};
    &:hover {
        border-color: var(--special);
    }
    & + button {
        margin-top: 5px;
    }
`
const Type = styled.div`
    display: flex;
    align-items: center;
    span {
        margin-right: 5px;
    }
    svg {
        margin-left: 4px;
    }
`

const Price = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    background-color: #FFFFFF1A;
    border-radius: 10px;
    padding: 10px;
    img {
        margin-right: 5px;
    }
`

const DiscountedPrice = styled.div`
    display: inline-block;
    
    span {
        text-decoration: line-through;
        font-weight: normal;
        opacity: 0.6;
        margin-left: 5px;
    }
`

const Item = styled(PriceWrap)`
    margin-bottom: 20px;
    justify-content: center;
    span:last-child {
        color: var(--subtext);
        margin-left: 5px;
        font-size: 12px;
    }
`

const BuyTypesError = ({ handleBuyCrypto, handleBuyFiat, setOpen, isLoading, item, discountPercent, setDiscountPercent, discountCode, setDiscountCode }) => {
    const [active, setActive] = useState(null)
    const store = useStore()

    useEffect(() => {
        if (
            !item?.prices?.eur
            || !store.settings.currencies?.find(i => i.ticker === 'EUR')?.active
        ) {
            setActive(2)
        }
    }, [])

    return (
        <>
            <h3>{t('Select Payment Method')}</h3>
            <p>{t('Select payment method to continue the purchase')}</p>

            <Item>
                <img src={item.image} width={100} alt="" />
                <span>{item.type}</span>
                <span>#{item.id}</span>
            </Item>

            {item?.prices?.bnb &&
                <ButtonCard onClick={() => setActive(2)} active={active === 2}>
                    <Type>
                        {t('Wallet')} (BNB)
                    </Type>
                    <Price>
                        <AssetIco ticker={'bnb'} />
                        {discountPercent > 0 ?
                            <DiscountedPrice>{formatAmount(discountedPrice(item.prices.bnb, discountPercent))} <span>{Number(item.prices.bnb).toFixed(2)}</span></DiscountedPrice>
                            : Number(item.prices.bnb).toFixed(2)
                        }

                    </Price>
                </ButtonCard>
            }
            {(item?.prices?.eur && store.settings.currencies?.find(i => i.ticker === 'EUR')?.active) &&
                <ButtonCard onClick={() => setActive(1)} active={active === 1}>
                    <Type>
                        <span>{t('Credit card')}</span>
                        <CardVisa />
                        <CardMastercard />
                    </Type>
                    <Price>
                        <AssetIco ticker={'eur'} />

                        {discountPercent > 0 ?
                            <DiscountedPrice>{formatAmount(discountedPrice(item.prices.eur, discountPercent))} <span>{Number(item.prices.eur).toFixed(2)}</span></DiscountedPrice>
                            : Number(item.prices.eur).toFixed(2)
                        }
                    </Price>
                </ButtonCard>
            }
            <Footer>
                <Button onClick={() => setOpen(false)}>{t('Cancel')}</Button>
                <Button
                    disabled={!active}
                    variant='primary'
                    onClick={() => active === 1 ? handleBuyFiat() : handleBuyCrypto()}
                    isLoading={isLoading}
                >{t('Continue')}</Button>
            </Footer>
            <DiscountCode
                discountPercent={discountPercent}
                setDiscountPercent={setDiscountPercent}
                discountCode={discountCode}
                setDiscountCode={setDiscountCode}
            />
        </>
    )
}

export default BuyTypesError
