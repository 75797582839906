import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '@/utils/hooks'
import { post } from '@/utils/api'
import { ReactComponent as ImgLoader } from '@/assets/loader.svg'
import Confirm from './CreateWallet/Confirm'
import Create from './CreateWallet/Create'

const CreateWallet = ({ onSuccess }) => {
    const store = useStore()
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    const [seed, setSeed] = useState(null)
    const [showConfirm, setShowConfirm] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                const result = await post({
                    path: 'wallet/create',
                    token: store.tokens.token,
                })
                setSeed(result.seed)
            } catch (error) {
                setError(error)
            } finally {
                setIsLoading(false)
            }
        })()
    }, [])

    const renderContent = () => {
        if (isLoading) {
            return <ImgLoader />
        }

        if (error !== null) {
            return <div>{t(error)}</div>
        }

        if (showConfirm) {
            return <Confirm seed={seed} setError={setError} onSuccess={onSuccess} />
        }

        if (seed !== null) {
            return <Create showConfirm={() => setShowConfirm(true)} seed={seed} />
        }
    }

    return renderContent()
}

export default CreateWallet
