import Decimal from 'decimal.js-light'

export const formatAmount = (amount, decimalLength) => {
    if (amount === null) {
        return ''
    }

    if (typeof amount === 'undefined') {
        return 'N/A'
    }

    if (typeof amount === 'string') {
        amount = parseFloat(amount)
    }

    return countDecimals(amount) < 2 ? amount.toFixed(2) : decimalLength ? amount.toFixed(decimalLength) : amount
}

const countDecimals = (amount) => {
    let str = amount.toString()
    if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
        return str.split('-')[1] || 0
    } else if (str.indexOf('.') !== -1) {
        return str.split('.')[1].length || 0
    }
    return str.split('-')[1] || 0
}

export const discountedPrice = (price, discount) => {
    if (discount > 0) {
        discount = new Decimal(discount)
        price = new Decimal(price)

        return price.minus(price.mul(discount).div(100)).toString()
    }

    return price
}
