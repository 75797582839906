import { useEffect, useState } from 'react'
import Table from '@/components/Table'
import Tabs from '@/components/Tabs'
import { CapitalizeFirstLetter } from '@/utils'
import { useTranslation } from 'react-i18next'
import { TableHeader } from './styled'

const AffTable = ({ affiliates }) => {
    const [tab, setTab] = useState(0)
    const [affData, setAffData] = useState([])
    const [ordersData, setOrdersData] = useState([])

    const { t } = useTranslation()

    const affColumns = [
        {
            name: t('Email'),
            selector: col => col.email
        },
        {
            name: t('Affiliates'),
            selector: col => col.children_count
        },
        {
            name: t('Purchases'),
            selector: col => col.purchases?.length
        },
        {
            name: t('Commissions'),
            selector: col => `${col.total_earnings} BNB`
        },
    ]
    const ordersColumns = [
        {
            name: t('Shoe'),
            selector: col => CapitalizeFirstLetter(col.shoe_type)
        },
        {
            name: t('Rarity'),
            selector: col => CapitalizeFirstLetter(col.rarity)
        },
        {
            name: t('Price'),
            selector: col => `${col.price?.bnb || 0} BNB`
        },
        {
            name: t('Tier'),
            selector: col => col.tier
        },
    ]

    useEffect(() => {
        setAffData(Object.values(affiliates))
        setOrdersData(Object.values(affiliates).reduce((acc, val) => acc.concat(val.purchases), []))
    }, [affiliates])

    return (
        <Table
            columns={tab === 0 ? ordersColumns : affColumns}
            data={tab === 0 ? ordersData : affData}
            title={
                <TableHeader>
                    <h3>{t('Your affiliates')}</h3>
                    <Tabs dark items={[t('Orders'), t('Affiliates')]} active={tab} setActive={setTab} />
                </TableHeader>
            }
        />
    )
}

export default AffTable