import styled from 'styled-components'
import { ReactComponent as Loader } from '@/assets/tail-spin.svg'

const PaymentPendingWrap = styled.div`
    background: var(--dbg);
    transition: opacity 1.5s;
    opacity: 80%;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg {
        margin-bottom: 20px;
    }
`

const messages = {
    processing: 'Waiting for transaction to be completed...',
    abandoned: 'Your transaction was cancelled',
    failed: 'There was an error while processing your transaction'
}

const PaymentPendingOverlay = ({ paymentResult }) => {
    return (
        <PaymentPendingWrap>
            <Loader />
            <p>{messages[paymentResult]}</p>
        </PaymentPendingWrap>
    )
}

export default PaymentPendingOverlay