import { useState } from 'react'
import { Block, Layout, Left, ModalWrapper, Right } from './index'
import Balances from './Spending/Balances'
import Transfer from './Transfer/index'
import { useStore } from '../../utils/hooks'
import NoWallet from './Spending/NoWallet'
import Actions from './Spending/Actions'
import NFTs from './NFT/SideAccordion'
import LockedAssets from './ICO/LockedSidebar'
import Modal from '../../components/Modal'

const Spending = ({ showWalletModal }) => {
    const store = useStore()
    const [showModal, setShowModal] = useState(false)

    const handleShowTransfer = () => {
        if (store?.user?.wallet_address) {
            setShowModal(true)
        } else {
            showWalletModal()
        }
    }

    return (
        <Layout>
            <Left>
                <Block>
                    <Balances />
                </Block>
                {window.innerWidth <= 800 &&
                    <Actions showTransfer={handleShowTransfer} />
                }
                <Block>
                    <NFTs isSpending />
                </Block>
                {store.user?.vaults && (
                    <Block>
                        <LockedAssets />
                    </Block>
                )}
            </Left>
            {window.innerWidth > 800 ?
                <Right>
                    <Block>
                        {store?.user?.wallet_address ?
                            <Transfer defaultSender={'spending'} />
                            :
                            <NoWallet showWalletModal={showWalletModal} />
                        }
                    </Block>
                </Right>
                :
                <Modal open={showModal} setOpen={() => setShowModal(false)} ariaHideApp={false}>
                    <ModalWrapper>
                        <Transfer defaultSender={'spending'} />
                    </ModalWrapper>
                </Modal>
            }
        </Layout>
    )
}

export default Spending
