import React from 'react'
import styled from 'styled-components'

const Badges = ({ badges }) => {
    return (
        <BadgesWrap>
            {badges.map((badge, index) => {
                return <div key={index}><span>{badge}</span></div>
            })}
        </BadgesWrap>
    )
}

export default Badges

const BadgesWrap = styled.div`
    div {
        margin-bottom: 5px;
        text-align: right;
        span {
            background: var(--secondary);
            font-size: 12px;
            border-radius: 35px;
            line-height: 22px;
            padding: 0 8px;
            display: inline-block;
            :first-letter {
                text-transform: capitalize;
            }
        }
        b {
            font-weight: 600;
        }
        @media (max-width: 400px) {
            margin-bottom: 0;
            span {
                font-size: 10px;
                line-height: 1.5;
                padding: 0 4px;
            }
        }
    }
`
