import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ImgEmpty } from '@/assets/ico-empty.svg'
import Button from '@/components/Button'

const NoWallet = ({ showWalletModal }) => {
    const { t } = useTranslation()
    return (
        <Content>
            <ImgEmpty />
            <p>
                {t('Transactions are not available until your wallet is not created or imported.')}
            </p>
            <Button variant={'primary'} onClick={showWalletModal}>{t('Create/import wallet')}</Button>
        </Content>
    )
}

export const Content = styled.div`
    text-align: center;
    padding: 40px 0;
    
    p {
        font-size: 14px;
        margin: 40px 0;
    }
`

export default NoWallet
