import { useEffect } from 'react'
import { useStore } from '@hooks'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { FullPageLoading } from '../components/FullPageLoading'

const Check = ({ children, notFound }) => {
    const nav = useNavigate()
    const store = useStore()

    useEffect(() => {
        if (!store.user && !store.isLoading) {
            store.set('prevPath', window.location.pathname + window.location.search)
            nav('/login')
        } else if (notFound) {
            nav('/marketplace')
        }
    }, [store.user, store.isLoading])

    return (store.isLoading || !store.user) ? <FullPageLoading /> : children
}

export default observer(Check)
