import styled, { css } from 'styled-components'
import Loader from '../assets/tail-spin.svg'

const PrimaryButton = styled.button`
    padding: 10px 20px;
    background: var(--special)  center center no-repeat;
    background-size: 20px 20px;
    color: var(--text);
    border-radius: 8px;
    border: 1px solid var(--special);
    appearance: none;
    box-shadow: none;
    font-weight: 500;
    font-size: 16px;
    transition: background-color .25s ease-out;
    ${({ fullWidth }) => fullWidth ? 'width: 100%;' : ''}
    ${props => props.isLoading && css`
      background-image: url(${Loader});
      color: transparent;
    `}

    :hover {
        background-color: #2e91a0;
        cursor: pointer;
    }
    :disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
    @media (max-width: 767px) {
        font-size: 14px;
        padding: 10px;
    }
`

const SecondaryButton = styled.button`
    padding: 10px 20px;
    color: #35A6B6;
    border-radius: 8px;
    border: 1px solid #35A6B6;
    appearance: none;
    box-shadow: none;
    font-weight: 500;
    font-size: 16px;
    transition: background-color .25s ease-out;
    backdrop-filter: blur(15px);
    background: none center center no-repeat;
    background-size: 20px 20px;
    ${({ fullWidth }) => fullWidth ? 'width: 100%;' : ''}
    ${props => props.isLoading && css`
      background-image: url(${Loader});
      color: transparent;
    `}

    :hover {
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
    }
    :disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
    @media (max-width: 767px) {
        font-size: 14px;
        padding: 10px;
    }
`
const BaseButton = styled.button`
    padding: 10px 20px;
    color: #35A6B6;
    border-radius: 8px;
    border: 1px solid #35A6B6;
    appearance: none;
    box-shadow: none;
    font-weight: 600;
    font-size: 16px;
    transition: background-color .25s ease-out;
    background: rgba(0, 0, 0, 0.0) center center no-repeat;
    background-size: 20px 20px;
    backdrop-filter: blur(15px);
    ${({ fullWidth }) => fullWidth ? 'width: 100%;' : ''}
    ${props => props.isLoading && css`
      background-image: url(${Loader});
      color: transparent;
    `}
    :hover {
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
    }
    :disabled {
        cursor: not-allowed;
    }
    @media (max-width: 767px) {
        font-size: 14px;
        padding: 10px;
    }
`

const Button = ({ variant = 'base', children, ...rest }) => {
    if (variant === 'primary') return <PrimaryButton {...rest}>{children}</PrimaryButton>
    if (variant === 'secondary') return <SecondaryButton {...rest}>{children}</SecondaryButton>
    return <BaseButton {...rest}>{children}</BaseButton>
}
export default Button
