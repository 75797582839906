import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useStore } from '@hooks'
import { Container, Grid } from '@/components'
import Tabs from '@/components/Tabs'
import Loading from '@/components/Loading'
import StringAppear from '@/components/StringAppear'
import { post } from '@/utils/api'
import { toastError, toastSuccess } from '@toast'
import { Wrap } from './styled'
import Shoe from '../Marketplace/Items/Shoe'
import Sock from '../Marketplace/Items/Sock'
import Water from '../Marketplace/Items/Water'
import Empty from './Empty'

const Inventory = () => {
    const [tab, setTab] = useState(0)
    const [loading, setLoading] = useState(false)
    const [equipped, setEquipped] = useState('')

    const store = useStore()
    const { t } = useTranslation()

    useEffect(() => {
        setEquipped(store.user?.active_shoe.id)
    }, [store.user?.active_shoe.id])

    const items = store.inventory

    const handleEquip = async (id) => {
        setLoading(true)
        try {
            const result = await post({
                path: 'user/change-shoe',
                token: store.tokens.token,
                body: {
                    shoe_id: id
                }
            })
            if (result.message) {
                toastError(result.message)
                return
            }
            await store.initUser()
            await store.getInventory()
            setEquipped(id)
            toastSuccess(t('Successfully equipped'))
        } catch (e) {
            toastError(t(e.toString()))
        } finally {
            setLoading(false)
        }
    }

    const renderContent = (tab) => {
        let gridItems

        switch (tab) {
            case 0:
                if (items.shoes?.length > 0) {
                    gridItems = items.shoes?.map((item, index) => <Shoe key={index} item={item} handleEquip={handleEquip} isEquipped={item.id === equipped} />)
                    break
                }

                return <Empty type={'shoes'} />
            case 1:
                if (items.socks?.length > 0) {
                    gridItems = items.socks?.map((item, index) => <Sock key={index} item={item} />)
                    break
                }

                return <Empty type={'socks'} />
            case 2:
                if (items.water?.length > 0) {
                    gridItems = items.water?.map((item, index) => <Water key={index} item={item} />)
                    break
                }

                return <Empty type={'water'} />
        }

        return <Grid col={5} colM={3} colL={4} colXs={2} colXsSize={1024}>{gridItems}</Grid>
    }

    return (
        <Wrap>
            <Container>
                <h1><StringAppear string="Inventory" /></h1>
                <Tabs items={[t('Shoes'), t('Socks'), t('Water')]} active={tab} setActive={setTab} />
                <Loading isLoading={loading}>
                    {renderContent(tab)}
                </Loading>
            </Container>
        </Wrap>
    )
}

export default observer(Inventory)
