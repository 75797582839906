import styled from 'styled-components'

const Progress = styled.div`
    width: 100%;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 30px;
    margin: 8px 0 4px;
    & > div {
        height: 100%;
        border-radius: 30px;
        background-color: ${props => props.color ? props.color : 'var(--lbg)'};
        width: ${({ max, value }) => `${100 * value / max}%`};
    }
    @media (max-width: 767px) {
        &, & > div {
            height: 6px;
        }
    }
`

const Input = ({ max = 5, value, color, ...rest }) => (
    <Progress max={max} value={value} color={color} {...rest}>
        <div />
    </Progress>
)
export default Input
