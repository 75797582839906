import { useTranslation } from 'react-i18next'
import Button from '@/components/Button'
import { Content } from '../BuyModal'
import { Price, PriceWrap } from '../../components/Price'
import Footer from './Footer'
import { Link } from 'react-router-dom'

const Success = ({ item, setOpen, discount }) => {
    const { t } = useTranslation()
    return (
        <>
            <h3>{t('Item purchased')}</h3>
            <p>{t('Thank you, your item was purchaced succesfully!')}</p>
            <Content>
                <PriceWrap>
                    <Price item={item} discount={discount} />
                    {item.type}
                </PriceWrap>
            </Content>
            <Footer>
                <Button onClick={() => setOpen(false)}>{t('Close')}</Button>
                <Link to="/inventory">
                    <Button variant={'primary'} onClick={() => setOpen(false)}>{t('Inventory')}</Button>
                </Link>
            </Footer>
        </>
    )
}

export default Success
