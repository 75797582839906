import styled from 'styled-components'
import { Grid, CardBase } from '@/components'

export const Wrap = styled.div`
    padding: 200px 0 80px;
    h1 {
        text-align: center;
        margin-bottom: 30px;
    }
    & > div > div:first-of-type {
        margin-bottom: 40px;
    }
    @media (max-width: 767px) {
        padding: 80px 0 40px;
    }
`

export const Card = styled.div`
    padding: 10px;
    border-radius: 10px;
    background: radial-gradient(47.92% 41.32% at 50% 50%, #366877 0%, #213742 100%);
    img {
        width: 100%;
    }
    ${Grid} {
        background: #19323C;
        margin: 10px -10px -10px;
        padding: 10px;
        border-radius: 0px 0px 10px 10px;
        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            &:not(&:nth-child(n + 4)) svg {
                margin-right: 5px;
            }
            &:nth-child(n + 4) {
                background: var(--subbg);
                border-radius: 8px;
                ::after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }
                & > svg path {
                    fill: #ffffff50;
                }
            }
        }
    }
    & > div:first-child {
        h4 {
            font-size: 14px;
        }
    }
`

export const ItemWrap = styled.section`
    margin: 140px auto 20px;
    padding: 20px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > div:first-of-type > div:first-of-type {
        ${CardBase}
    }
    & > svg {
        margin-bottom: 20px;
        cursor: pointer;
    }
    @media (max-width: 767px) {
        min-height: 100vh;
    }
`

export const SocksWrap = styled(Grid)`
    margin: 20px 0;
    & > div {
        background: var(--subbg);
        border-radius: 8px;
        ::after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }
    }
`