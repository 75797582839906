import React, { createContext, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as Sentry from '@sentry/browser'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import Cookies from 'js-cookie'
// import { handlePostTranslations, handleGetTranslations } from './utils/api'
import Routes from './Routes'
import Store from './utils/store'
//import { WagmiConfig, createClient } from 'wagmi'
//import { getDefaultProvider } from 'ethers'
//import WalletsConnector from './pages/Wallets/WalletsConnector/'

// const client = createClient({
//   autoConnect: true,
//   provider: getDefaultProvider(),
// })

export const StoreContext = createContext({})

const toastConfig = {
  theme: 'dark',
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
}

function App() {
  const store = Store()

  if (window.location.hostname !== 'localhost') {
    Sentry.init({
      dsn: 'https://8e52fd180e70415298dfd0408e85fad9@sentry2.besk.com/3',
      tunnel: 'https://sfjtzvvdks.tiptop.io/sentry.php'
    })
  }

  const cookieProps = {
    expires: 30,
    domain: 'tiptop.io'
  }

  // Check and save affiliate code
  if (window.location.pathname.includes('/a/')) {
    const hash = window.location.pathname.split('/a/')[1]
    Cookies.set('affiliate_code', hash, cookieProps)
    Cookies.set('affiliate_code_show', true, cookieProps)
  }
  // Check and save affiliate code which will show referers email
  if (window.location.pathname.includes('/as/')) {
    const hash = window.location.pathname.split('/as/')[1]
    Cookies.set('affiliate_code', hash, cookieProps)
    Cookies.set('affiliate_code_show', true, cookieProps)
    window.location.href = '/login'
  }
  // Check if user came with chosen email
  const searchParamEmail = window.location.search.split('email=')
  if (searchParamEmail[1]) {
    Cookies.set('email_to_register', searchParamEmail[1], cookieProps)
  }

  useEffect(() => {
    (async () => {
      await store.getAppSettings()

      if (store.tokens.token) {
        await store.initUser()
        await store.getInventory()
      }

      i18n
        .use(Backend)
        .use(initReactI18next)
        .init({
          lng: localStorage.getItem('selectedLanguage'),
          fallbackLng: 'en',
          load: 'currentOnly',
          interpolation: {
            escapeValue: false,
          },
          saveMissing: true,
          saveMissingTo: 'all',
          keySeparator: false,
          nsSeparator: false,
          crossDomain: false,
          backend: {
            loadPath: 'https://sfjtzvvdks.tiptop.io/api/translations?lang[]={{lng}}',
            addPath: 'https://sfjtzvvdks.tiptop.io/api/translations/{{lng}}',
            parsePayload: function (namespace, key, fallbackValue) {
              return {
                translations: [key]
              }
            },
            parse: function (data) {
              return data && JSON.parse(data) && typeof JSON.parse(data) === 'object' && Object.values(JSON.parse(data))[0]
            },
          },
          debug: true,
          react: {
            useSuspense: false,
          },
        }, () => {
          store.set('isLoading', false)
        })
    })()
  }, [store])

  return (
    // <WagmiConfig client={client}>
    <StoreContext.Provider value={store}>
      <div>
        {/*<WalletsConnector />*/}
        <Routes />
        <ToastContainer {...toastConfig} />
      </div>
    </StoreContext.Provider>
    // </WagmiConfig>
  )
}

export default App
