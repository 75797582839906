import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useStore } from '@/utils/hooks'
import Accordion from '@/components/Accordion'
import { Grid, Flex } from '@/components'
import { ReactComponent as ImgKarma } from '@/assets/karma.svg'
import { ReactComponent as ImgSpeed } from '@/assets/speed.svg'
import { ReactComponent as ImgPerformance } from '@/assets/performance.svg'

const NftBalances = ({ isSpending }) => {
    const [nfts, setNfts] = useState([])

    const store = useStore()
    const { t } = useTranslation()

    useEffect(() => {
        setNfts(isSpending ? store.inventory?.shoes.filter((item) => { return !item.isDemo }) : store.wallet.shoes)
    }, [store.inventory?.shoes.length, store.wallet?.shoes.length])

    // const nfts = isSpending ? store.inventory.shoes.filter((item) => { return !item.isDemo }) : store.wallet.shoes

    return (
        <Wrap>
            <Accordion title={<h3>{t('My')} NFTs ({nfts.length})</h3>}>
                {nfts.length > 0 ?
                    <Content>
                        {nfts?.map((item) => (
                            <Asset colExact="100px 1fr" colXsExact="60px 1fr" key={item.id} disableAt={1}>
                                <div>
                                    <img src={item.image} alt={item.name} />
                                </div>
                                <Flex column justify="space-between">
                                    <div>
                                        <h4>{item.name}</h4>
                                        <Flex justify="space-between">
                                            <span>{item.min_speed}-{item.max_speed}km/h</span>
                                            <span>#{item.code}</span>
                                        </Flex>
                                    </div>
                                    <div>
                                        <Performance>
                                            <div data-tip={'Performance'}>
                                                <ImgPerformance />
                                                {item.base_performance}
                                            </div>
                                            <div data-tip={'Stamina'}>
                                                <ImgSpeed />
                                                {item.base_stamina}
                                            </div>
                                            <div data-tip={'Karma'}>
                                                <ImgKarma />
                                                {item.base_karma}
                                            </div>
                                        </Performance>
                                        <Level>
                                            <b>{item.rarity}</b> Lvl {item.level} / {item.max_level}
                                        </Level>
                                    </div>
                                </Flex>

                            </Asset>
                        ))}
                    </Content>
                    :
                    <p>{t('You have no NFTs')}</p>
                }
            </Accordion>
        </Wrap>
    )
}

const Wrap = styled.div`
    font-size: 14px;
    & > div > div:first-child {
        cursor: pointer;
    }
    & > div > div:last-child {
        padding: 0;
        overflow-y: scroll;
    }
`
export const Content = styled.div`
    margin-top: 20px;
    img {
        width: 100%;
    }
    
    & > div {             
        span {
            opacity: 0.5;
        }
        &:hover {
            span {
                opacity: 1;
            }
        }
    }
`

const Asset = styled(Grid)`
    & > div:first-child {
        background: radial-gradient(47.92% 41.32% at 50% 50%,#366877 0%,#213742 100%);
        border-radius: 10px;
    }
    & + div {
        margin-top: 20px;
    }
`

const Level = styled.div`
    width: 100%;
    background: var(--special);
    border-radius: 20px;
    padding: 2px 10px;
    width: 100%;
    text-align: center;
    b {
        margin-right: 5px;
        text-transform: uppercase;
    }
`

const Performance = styled.div`
    margin: 5px 0;
    &, & > div {
        display: flex;
        align-items: center;
    }
    & > div {
        & + div {
            margin-left: 12px;
        }
        font-size: 12px;
    }
    svg {
        margin-right: 5px;
    }
`

export default observer(NftBalances)
