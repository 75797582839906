import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useStore } from '@/utils/hooks'
import Button from '@/components/Button'
import { formatAmount } from '@/utils/amount'
import { post } from '@/utils/api'
import BuyModal from '../../../pages/Marketplace/Buy/Items/Shoe'
import Modal from '@/components/Modal'
import Error from '@/components/Error'
import { toastSuccess } from '@toast'
import Amount from './Amount'
import Assets from './Assets'
import Direction from './Direction'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

const Transfer = ({ defaultSender }) => {
    const store = useStore()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [sender, setSender] = useState(defaultSender || 'wallet')
    const [receiver, setReceiver] = useState(null)
    const [selectedAsset, setSelectedAsset] = useState('bnb')
    const [assets, setAssets] = useState({})
    const [amount, setAmount] = useState('')
    const [isValid, setIsValid] = useState(false)
    const [error, setError] = useState(null)
    const [showAssets, setShowAssets] = useState(false)
    const [isNft, setIsNft] = useState(false)
    const [nftModal, setNftModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const fee = useMemo(() => {
        // return Number(store.settings.transaction_fee)
        // Temporary fix till wallet fixes come
        return 0.003
    }, [store.settings])

    useEffect(() => {
        const url = window.location.search.split('id=')[1]
        if (url) {
            const shoe = store.inventory.shoes.find(i => i.id == url)
            if (shoe && shoe.id !== store.user?.active_shoe.id) {
                setSelectedAsset(shoe)
                setIsNft(true)
            }
            navigate('/wallet', { replace: true })
        }
    }, [store.inventory, navigate])

    useEffect(() => {
        if (sender === 'wallet') {
            setAssets(store.wallet.assets)
        } else {
            setAssets(store.user.wallet)
        }
    }, [sender, store?.wallet?.assets, store?.user?.wallet])

    useEffect(() => {
        if (!isNft) {
            validateAmount()
        }
    }, [amount, isNft])

    const validateAmount = () => {
        setError('')
        if (Number(amount) <= 0) {
            setIsValid(false)
            if (Number(amount) < 0) {
                setError(t('Insufficient funds'))
            }
            return
        }

        if (selectedAsset === 'bnb') {
            if (
                Number(amount) + fee <= Number(assets[selectedAsset])
            ) {
                setError(null)
                if (amount === '' || Number(amount) === 0) {
                    setIsValid(false)
                } else {
                    setIsValid(true)
                }
            } else {
                setError(t('Not enough BNB funds'))
                setIsValid(false)
            }
        } else {
            if (Number(amount) > Number(assets[selectedAsset])) {
                setError(`${t('Not enough')} ${selectedAsset.toUpperCase()} ${t('funds')}`)
                setIsValid(false)
            } else {
                if (fee > Number(assets.bnb)) {
                    setError(t('Not enough BNB to cover fees'))
                    setIsValid(false)
                } else {
                    setIsValid(true)
                    setError(null)
                }
            }
        }
    }

    const setDirection = (sender) => {
        setSender(sender)
        setAmount('')
        setError(null)
    }

    const handleAssetClick = (asset) => {
        setSelectedAsset(asset)
        setShowAssets(!showAssets)
        setAmount('')
    }

    const resolveTransferType = () => {
        if (receiver !== null) {
            return 'FROM_WALLET_TO_WALLET'
        } else if (sender === 'wallet') {
            return 'FROM_WALLET_TO_APP'
        } else {
            return 'FROM_APP_TO_WALLET'
        }
    }

    const handleTransfer = async () => {
        setError(null)
        setIsLoading(true)

        if (isNft) {
            setNftModal(true)
            setIsLoading(false)
            return
        }

        try {
            let currency = store?.settings?.assets.find(item => item.ticker === selectedAsset.toUpperCase())?.ticker

            if (currency === 'BNB') {
                currency = 'BINANCE'
            }

            const result = await post({
                path: 'wallet/transfer',
                token: store.tokens.token,
                body: {
                    type: resolveTransferType(),
                    amount: amount.toString(),
                    currency,
                    wallet: receiver,
                }
            })

            if (result.respStatus !== 200) {
                setError(result)
            } else {
                toastSuccess(t('Transaction submitted successfully!'))
                setAmount('')
                store.set('pingTransactions', true)

                await store.initUser(true)
            }
        } catch (e) {
            setError(e.toString())
        } finally {
            setIsLoading(false)
        }
    }

    const handleNftTransfer = async () => {
        if (fee >= Number(assets.bnb)) {
            setError(t('Not enough BNB funds'))
            setIsValid(false)
            setNftModal(false)
            return
        }
        try {
            const result = await post({
                path: 'nft/transfer',
                token: store.tokens.token,
                body: {
                    type: resolveTransferType(),
                    shoes: selectedAsset.code,
                    wallet: receiver,
                }
            })

            if (result.errors || result.message) {
                setError(result)
            } else {
                toastSuccess(t('NFT transaction submitted successfully!'))
                store.set('pingTransactions', true)

                if (resolveTransferType() === 'FROM_APP_TO_WALLET') {
                    await store.getInventory()
                }
                if (resolveTransferType() !== 'FROM_APP_TO_WALLET') {
                    setTimeout(async () => {
                        await store.getWallet(true)
                    }, 5000)
                }
            }
        } catch (e) {
            setError(t(e.toString()))
        } finally {
            setIsLoading(false)
            setNftModal(false)
            setShowAssets(true)
        }
    }

    return (
        <>
            <h3>Transfer</h3>
            <Wrap>
                <Direction
                    setDirection={setDirection}
                    setReceiver={setReceiver}
                    receiver={receiver}
                    sender={sender}
                />

                <InnerBlock>
                    <Assets
                        showAssets={showAssets}
                        assets={assets}
                        setIsNft={setIsNft}
                        handleAssetClick={handleAssetClick}
                        selectedAsset={selectedAsset}
                        store={store}
                        isSpending={defaultSender === 'spending'}
                    />
                    {!showAssets && !isNft && <Amount
                        setAmount={setAmount}
                        amount={amount}
                        assets={assets}
                        selectedAsset={selectedAsset}
                        fee={fee}
                        setError={setError}
                    />}
                    <Error error={error} />
                </InnerBlock>

                <Fee>{t('Transfer fee')}: {formatAmount(fee)} BNB</Fee>

                <Button
                    onClick={handleTransfer}
                    isLoading={isLoading}
                    // disabled={!isValid || isLoading}
                    variant={'primary'}
                >
                    {t('Transfer')}
                </Button>
            </Wrap>
            <Modal open={nftModal} setOpen={setNftModal}>
                <div>
                    <BuyModal item={selectedAsset} setOpen={setNftModal} handleBuy={handleNftTransfer} isTransfer isLoggedIn={!!store.user} />
                </div>
            </Modal>
        </>
    )
}

const Wrap = styled.div`
    margin: 0 auto 20px auto;
    max-width: 480px;
    
    button {
        width: 100%;
    }
`

const Fee = styled.div`
    opacity: 0.3;
    font-size: 12px;
    margin-bottom: 15px;
`

export const Label = styled.strong`
    font-weight: normal;
    color: #35A6B6;
    font-size: 12px;
`

export const InnerBlock = styled.div`
    border-radius: 10px;
    background-color: #0D2631;
    padding: 20px;
    margin-bottom: 15px;
`

export default observer(Transfer)
