import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Input from '@/components/Input'
import Button from '@/components/Button'
import { t } from '@/utils'
import { handleAddAffiliate } from '@api'
import { toastError, toastSuccess } from '@toast'

const Wrap = styled.section`
    padding: 200px 20px;
    max-width: 600px;
    margin: 0 auto;
    h1 {
        text-align: center;
    }
    form > div {
        width: 100%;
        margin: 20px 0 0;
    }
`

const Footer = styled.div`
    margin-top: 10px;
    text-align: center;
`

const CodeWrap = styled.div`
    border-radius: 10px;
    background: var(--lbg);
    padding: 20px;
    margin-top: 20px;
    b {
        cursor: pointer;
        font-size: 18px;
    }
`

const Affiliate = () => {
    const { t } = useTranslation()

    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const body = {
                email,
                code: 'n6Epc2vTHVqOXc58RwuN'
            }
            const result = await handleAddAffiliate(body)
            if (result.affiliate) {
                setCode(result.affiliate)
                toastSuccess(t('Success. Affiliate code created.'))
                return
            }
            toastError(`${t('There is no code assigned to')} ${email}`)
        } catch (err) {
            toastError(t('There was an error while creating code'))
        }
    }

    const handleCopy = (text = code) => {
        navigator.clipboard.writeText(text)
        toastSuccess(t('Copied to clipboard'))
    }

    return (
        <Wrap>
            <h2>{t('Get Affiliate Code')}</h2>
            <form onSubmit={handleSubmit}>
                <Input value={email} onChange={setEmail} label={t('Email')} required type="email" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" />
                <Footer>
                    <Button variant={'primary'}>{t('Submit')}</Button>
                </Footer>
                {code && (
                    <CodeWrap>
                        <p>{t('Affiliate code for')} {email}:</p>
                        <p><b onClick={handleCopy}>{code}</b></p>
                        <br />
                        <p>{t('Affiliate URL')}:</p>
                        <p><b onClick={() => handleCopy(`https://tiptop.io/a/${code}`)}>https://tiptop.io/a/{code}</b></p>
                    </CodeWrap>
                )}
            </form>
        </Wrap>
    )
}

export default Affiliate
