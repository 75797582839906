import React, { useState } from 'react'
import { Card } from '../styled'

const Wrap = ({ children }) => {
    const [mousePos, setMousePos] = useState({ x: 100, y: 100 })

    const handleMouseMove = (e) => {
        const { currentTarget } = e
        const rect = currentTarget.getBoundingClientRect(),
            x = e.clientX - rect.left,
            y = e.clientY - rect.top

        setMousePos({ x, y })
    }

    return (
        <Card
            mousePos={mousePos}
            onMouseMove={handleMouseMove}
        >
            {children}
        </Card>
    )
}

export default Wrap