import styled from 'styled-components'
import React, {useState} from 'react'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import {t} from '../../../../utils'
import {useStore} from '../../../../utils/hooks'
import {get} from '@api'
import {toastError} from '../../../../utils/toast'

const DiscountCode = ({discountPercent, setDiscountPercent, discountCode, setDiscountCode}) => {
    const [showInput, setShowInput] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const store = useStore()

    const onCancel = () => {
        setDiscountPercent(0)
        setDiscountCode(null)
        setShowInput(false)
        setError(null)
    }

    const onChange = () => {
        setDiscountPercent(0)
        setDiscountCode(null)
    }

    const onApply = async () => {
        setIsLoading(true)

        try {
            setIsLoading(true)
            const result = await get({
                path: `discount-codes/${discountCode}`,
                token: store.tokens.token
            })

            if (!result) {
                toastError('Something went wrong')
            } else {
                if (result.message) {
                    setError(result.message)
                } else if (result.discount_percent) {
                    setDiscountPercent(result.discount_percent)
                } else {
                    toastError('Something went wrong')
                }
            }
        } catch (err) {
            toastError('Something went wrong')
        } finally {
            setIsLoading(false)
        }
    }

    const renderBody = () => {
        if (discountPercent > 0) {
            return <DiscountApplied>
                Your promo code <b>{discountCode}</b> with <b>{discountPercent.toFixed(2)}%</b> discount has been applied!
                <DiscountLink onClick={onChange}>Use another code</DiscountLink>
            </DiscountApplied>
        }

        if (!showInput) {
            return <DiscountLink onClick={() => setShowInput(true)}>
                Do you have a discount code?
            </DiscountLink>
        }

        return <FormWrapper>
            <Input
                label="Discount code"
                onChange={val => {setDiscountCode(val.toUpperCase()); setError(null)}}
                value={discountCode}
                type="text"
                placeholder={'Enter code'}
                variant="boxed"
                maxLength={35}
                buttonInline
                autoFocus
                disabled={isLoading}
                button={
                    <Buttons>
                        <Button
                            onClick={onApply}
                            disabled={discountCode === null || discountCode.length === 0}
                            variant="primary"
                            isLoading={isLoading}
                        >
                            {t('Apply')}
                        </Button>
                        <Button
                            onClick={onCancel}
                            variant="secondary"
                            disabled={isLoading}
                        >
                            {t('Cancel')}
                        </Button>
                    </Buttons>
                }
            />
            {error && <Error>{error}</Error>}
        </FormWrapper>
    }

    return (
        <Wrapper>
            {renderBody()}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    margin-top: 20px;
    font-size: 12px;
    text-align: center;
`

const DiscountLink = styled.div`
    opacity: 0.5;
    cursor: pointer;
    text-decoration: underline;
    
    &:hover {
        opacity: 1;
    }
`

const DiscountApplied = styled.div`
    font-size: 13px;
`

const Buttons = styled.div`
    button {
        margin-left: 10px;
        font-size: 12px;
    }
`

const FormWrapper = styled.div`
    text-align: left;
`

export const Error = styled.div`
    color: var(--red);
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
`

export default DiscountCode
