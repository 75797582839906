import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { formatAmount } from '@/utils/amount'
import { ReactComponent as ImgDropdown } from '@/assets/ico-dropdown.svg'
import { Label } from './index'

const Assets = ({ showAssets, assets, handleAssetClick, selectedAsset, store, setIsNft, isSpending }) => {
    const { t } = useTranslation()

    const balances = useMemo(() => {
        const result = []

        store.settings.assets?.forEach(asset => {
            if (asset.active) {
                result.push({
                    ...asset, balance: assets[asset.ticker.toLowerCase()]
                })
            }
        })

        return result
    }, [store.settings, store?.user, JSON.stringify(assets)])

    const nfts = isSpending ? store.inventory.shoes.filter((item) => { return !item.isDemo }) : store.wallet.shoes

    const handleClick = (item) => {
        const isNft = !item.ticker
        handleAssetClick(isNft ? item : item.ticker.toLowerCase())
        setIsNft(isNft)
    }

    return (
        <>
            <Label>{showAssets ? t('Select asset') : t('Asset')}</Label>
            <AssetSelect>
                {balances.map((item, index) => {
                    return (showAssets || item.ticker.toLowerCase() === selectedAsset) &&
                        <Asset key={item.ticker} onClick={() => handleClick(item)}>
                            <img
                                src={item.image}
                                alt={item.ticker}
                            />
                            <div>
                                {item.ticker.toUpperCase()}
                                <span>{t('Balance')}: {formatAmount(item.balance)}</span>
                            </div>
                            {!showAssets && <ImgDropdown />}
                        </Asset>
                })}
                {showAssets && nfts.length > 0 && <Label>NFTs</Label>}
                {nfts.map(item => {
                    const isEquipped = store.user.active_shoe?.id === item.id
                    return (showAssets || item.id === selectedAsset?.id) && (
                        <Asset isEquipped={isEquipped} key={item.name} onClick={() => isEquipped ? {} : handleClick(item)}>
                            <img
                                src={item.image}
                                alt={item.name}
                            />
                            <div>
                                {item.name}
                                {isEquipped && <span><b>{t('Equipped')}</b></span>}
                            </div>
                            {!showAssets && <ImgDropdown />}
                        </Asset>
                    )
                })}
            </AssetSelect>
        </>
    )
}

export const AssetSelect = styled.div`
    margin-top: 10px;
    position: relative;
`

export const Asset = styled.div`
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid transparent;
    cursor: ${({ isEquipped }) => isEquipped ? 'not-allowed' : 'pointer'};
    padding: 10px;
    position: relative;
    ${({ active }) => active ? `
        border-color: rgb(53,166,182, 0.5);
    ` : ''}
    &:hover {
        border-color: #19323C;
        span {
            opacity: 1;
        }
    }
    
    span {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        opacity: 0.6;
        b {
            color: var(--special);
        }
    }
    
    img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }
    
    svg {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
`

export default observer(Assets)
