import styled from 'styled-components'

const ErrorWrap = styled.div`
    &, p {
        font-size: 12px;
    }
    color: var(--red);
    padding: 10px 10px 0 10px;
`

const Error = ({ error }) => {
    let renderError
    if (!error) {
        return
    }
    if (typeof error === 'string') {
        renderError = error
    } else {
        if (error.errors?.length) {
            renderError = (
                error.errors.map(i => {
                    const showVal = typeof i === 'string' ? i : i.message
                    return (
                        <p key={showVal}>- {showVal}</p>
                    )
                })
            )
        } else {
            renderError = error.message || 'There was an error, please try again later.'
        }
    }

    return renderError && (
        <ErrorWrap>{renderError}</ErrorWrap>
    )
}

export default Error
