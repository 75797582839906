import styled from 'styled-components'

export const Wrap = styled.section`
    padding: 200px 0;
    h1 {
        font-size: 48px;
        text-align: center;
        margin-bottom: 60px;
    }
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            margin-right: 5px;
            height: 20px;
        }
    }
    @media (max-width: 767px) {
        padding: 80px 0;
    }
`

export const Box = styled.div`
    padding: 30px;
    background: var(--subbg);
    border-radius: 10px;
    @media (max-width: 600px) {
        padding: 15px;
    }
`

export const EarningsWrap = styled(Box)`
`

export const AssetEarnings = styled.div`
    & > p:nth-child(1) {
        font-size: 16px;
    }

    & > p:nth-child(2) {
        margin-bottom: 10px;
        font-size: 28px;
        display: flex;
        align-items: center;
        font-weight: bold;
        span {
            font-size: 14px;
            margin-left: 10px;
            font-weight: 500;
        }
    }
    & > p:nth-of-type(3) {
        margin-bottom: 20px;
        font-size: 12px;
        color: var(--subtext);
        b {
            color: var(--text);
        }
    }
    & > button {
        width: 100%;
        margin-bottom: 20px;
        & + div {
            width: 100%;
        }
    }
`
export const StatisticsWrap = styled.div`
    & > div {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ffffff10;
        padding: 10px 0;
        p {
            font-size: 14px;
        }
        & > p:first-child {
            color: var(--special);
        }
    }
`
export const AffiliateBox = styled(Box)`
    margin-top: 20px;
`
export const TableHeader = styled.div`
    margin-bottom: 20px;
    font-size: 16px;
    & > div {
        margin: 0;
    }
    @media (min-width: 600px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    @media (max-width: 600px) {
        & > div {
            margin-top: 10px;
        }
    }
`
