import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '@/components/Button'
import { Grid } from '@/components'
import { ReactComponent as ImgKarma } from '@/assets/karma.svg'
import { ReactComponent as ImgSpeed } from '@/assets/speed.svg'
import { ReactComponent as ImgPerformance } from '@/assets/performance.svg'
import { BuyHeader } from './components/BuyHeader'
import { ProgressBar } from './components/ProgressBar'
import { Price, PriceWrap } from '../../components/Price'
import ItemCard, { ItemControl, ItemControls, ItemHeader } from './components/ItemCard'
import Footer from '../Modal/Footer'
import DiscountCode from '../Modal/DiscountCode'

const Shoe = ({ item, setOpen, handleBuy, isLoading, isTransfer, isLoggedIn, discountPercent, setDiscountPercent, discountCode, setDiscountCode }) => {
    const [showVideo, setShowVideo] = useState(true)

    const { t } = useTranslation()

    const baseMaxStat = useMemo(() => {
        let max = item.base_performance

        if (item.base_stamina > max) {
            max = item.base_stamina
        }

        if (item.base_karma > max) {
            max = item.base_karma
        }

        return max
    }, [item])

    return item && (
        <>
            {!isTransfer && <BuyHeader />}
            <Grid col={2}>
                <ItemCard>
                    <ItemHeader>
                        {item.type}
                        <ItemControls>
                            <ItemControl active={!showVideo} onClick={() => setShowVideo(false)}>
                                2D
                            </ItemControl>
                            <ItemControl active={showVideo} onClick={() => setShowVideo(true)}>
                                3D
                            </ItemControl>
                        </ItemControls>
                    </ItemHeader>
                    {!showVideo ?
                        <img
                            src={item.image}
                            alt={item.name}
                        />
                        :
                        <video controls={false} autoPlay={true} loop={true}>
                            <source src={item.video} type='video/mp4' />
                        </video>
                    }
                    <div>
                        <div>
                            <b>{item.rarity}</b>
                        </div>
                    </div>
                </ItemCard>

                <RightSide>
                    <ProgressBars colExact="15px minmax(0, 3fr) minmax(0, 5fr) minmax(0, 1fr)" disableAt={1} gap={10} align="center">
                        <ProgressBar
                            value={item.base_performance}
                            max={baseMaxStat}
                            color='#FF5B66'
                        >
                            <ImgPerformance />
                            <b>{t('Performance')}</b>
                        </ProgressBar>

                        <ProgressBar
                            value={item.base_stamina}
                            max={baseMaxStat}
                            color='#FFA337'
                        >
                            <ImgSpeed />
                            <b>{t('Stamina')}</b>
                        </ProgressBar>

                        <ProgressBar
                            value={item.base_karma}
                            max={baseMaxStat}
                            color='#4ABEFF'
                        >
                            <ImgKarma />
                            <b>{t('Karma')}</b>
                        </ProgressBar>
                    </ProgressBars>
                    {!isTransfer && (
                        <ShoePriceWrap>
                            <Price item={item} discount={discountPercent} />
                            {item.type}
                        </ShoePriceWrap>
                    )}
                </RightSide>
            </Grid>
            <Footer>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                {isLoggedIn
                    ? <Button isLoading={isLoading} onClick={() => handleBuy({ shoes_id: item.id })} variant={'primary'}>{isTransfer ? t('Transfer') : t('Buy')}</Button>
                    : <Link
                        onClick={() => {
                            setOpen(false)
                            window.scrollTo(0, 0)
                        }}
                        to="/login"
                    >
                        <Button variant="primary">{t('Login')}</Button>
                    </Link>}
                {!isTransfer &&
                <DiscountCode
                    discountPercent={discountPercent}
                    setDiscountPercent={setDiscountPercent}
                    discountCode={discountCode}
                    setDiscountCode={setDiscountCode}
                />}
            </Footer>
        </>
    )
}

const ShoePriceWrap = styled(PriceWrap)`
    margin-top: 20px;
    justify-content: center;
    @media (max-width: 767px) {
        margin-top: 10px;
    }
`

const ProgressBars = styled.div`
    & > div {
        margin-bottom: 25px;
        
        @media (max-width: 767px) {
            margin-bottom: 5px;
        }
    }
`

const RightSide = styled.div`
    margin: 0 30px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    
    &:first-child {
        flex: 1;
    }
`
export default Shoe
