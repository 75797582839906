import React from 'react'
import { observer } from 'mobx-react-lite'
import { Grid, Flex } from '@/components'
import { SocksImages } from '../../../components/NftImages'
import ItemFooter from '../components/ItemFooter'
import { ItemPreview } from '../styled'
import styled, { css } from 'styled-components'
import Performance from '../components/Performance'
import Wrap from './Wrap'

const Sock = ({ item, handleBuy }) => {
    return (
        <Wrap key={item.id}>
            <Content onClick={() => handleBuy && handleBuy(item, 'socks')} clickable={!!handleBuy}>
                <Flex justify="space-between">
                    <h4>{item.title}</h4>
                    <Performance item={item} />
                </Flex>
                <img
                    src={SocksImages[item.type]}
                    alt={item.title}
                />
                <Grid col={4} disableAt={1}>
                    {item.code && <div>#{item.code}</div>}
                </Grid>
                {item.quantity && (<QuantityWrap>{item.quantity}x</QuantityWrap>)}
            </Content>
            {handleBuy && <ItemFooter item={item} type={'socks'} handleBuy={handleBuy} />}
        </Wrap>
    )
}

const Content = styled(ItemPreview)`
    ${props => props.clickable &&
        css`
        cursor: pointer;
    `};
    img {
        margin-left: 10%;
        margin-top: 50px;
    }
`
export const QuantityWrap = styled.span`
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-weight: bold;
`

export default observer(Sock)
