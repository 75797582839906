import { useState, useEffect } from 'react'

const allLetters = 'QWERTYUIOPLKJHGFDSAZXCVBNM'

const StringAppear = ({ string }) => {
    const [changingStr, setChangingStr] = useState(string)

    useEffect(() => {
        let interval
        let iteration = 0

        clearInterval(interval)

        interval = setInterval(() => {
            setChangingStr(
                changingStr.split('').map((letter, index) => {
                    if (index < iteration) {
                        return string[index]
                    }

                    return allLetters[Math.floor(Math.random() * 26)]
                }).join('')
            )

            if (iteration >= string.length) {
                clearInterval(interval)
            }

            iteration += 1 / 3
        }, 20)

    }, [string])

    return changingStr
}

export default StringAppear