import styled, { css } from 'styled-components'
import { DOTS, usePagination } from '../hooks/usePagination'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const paginationRange = usePagination({
        currentPage,
        totalPageCount: totalPages,
        siblingCount: 2,
    })

    const onNext = () => {
        if (currentPage !== totalPages) {
            onPageChange(currentPage + 1)
        }
    }

    const onPrevious = () => {
        if (currentPage !== 1) {
            onPageChange(currentPage - 1)
        }
    }

    return totalPages > 1 && (
        <StyledPagination>
            <Item disabled={currentPage === 1} onClick={onPrevious} >
                <ArrowPrev />
            </Item>
            {paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                    return <Item key={index} dots>&#8230;</Item>
                }

                return (
                    <Item key={index} selected={pageNumber === currentPage} onClick={() => onPageChange(pageNumber)}>
                        {pageNumber}
                        {pageNumber !== totalPages && paginationRange[index + 1] !== DOTS && <span>,</span>}
                    </Item>
                )
            })}
            <Item
                disabled={currentPage === totalPages}
                onClick={onNext}
            >
                <ArrowNext />
            </Item>
        </StyledPagination>
    )
}

const StyledPagination = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    margin-top: 40px;
`

const Item = styled.li`
    padding: 5px;
    cursor: pointer;
    
    &:hover {
        opacity: 0.6;
    }
    
    ${props =>
        props.dots &&
        css`
        cursor: default;
        &:hover {
            opacity: 1;
        }
    `};
    
    ${props =>
        props.disabled &&
        css`
        opacity: 0.3;
        cursor: default;
        &:hover {
            opacity: 0.3;
            cursor: default;
        }
    `};
    ${props =>
        props.selected &&
        css`
        color: #35A6B6;
    `};
    
    span {
        padding-left: 5px;
    }
`

const Arrow = styled.div`
    width: 0;
    height: 0;
    border-width: 9.5px 0 9.5px 12px;
    border-color: transparent transparent transparent #35A6B6;
    border-style: solid;
`

const ArrowNext = styled(Arrow)`
    margin-left: 5px;
`

const ArrowPrev = styled(Arrow)`
    margin-right: 5px;
    transform: rotate(180deg);
`

export default Pagination
