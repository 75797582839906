import { useStore } from '@hooks'
import { observer } from 'mobx-react-lite'
import { FullPageLoading, NavLoading } from '../components/FullPageLoading'

const Check = ({ children, nav }) => {
    const store = useStore()

    return store.isLoading ? (nav ? <NavLoading /> : <FullPageLoading />) : children
}

export default observer(Check)
