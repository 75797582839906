import DataTable from 'react-data-table-component'

const customStyles = {
    responsiveWrapper: {
        style: {
            borderRadius: 0
        }
    },
    rows: {
        style: {
            backgroundColor: 'transparent',
            color: 'var(--text)',
            borderBottomColor: '#ffffff10'
        },
    },
    header: {
        style: {
            backgroundColor: 'transparent',
            color: 'var(--text)',
            padding: 0
        }
    },
    pagination: {
        style: {
            backgroundColor: 'transparent',
            color: 'var(--text)',
            button: {
                fill: 'var(--special)',
                '&:disabled': {
                    fill: '#35A6B650'
                }
            },
            '@media (max-width: 599px)': {
                '& > div > div': {
                    visibility: 'hidden'
                }
            }
        }
    },
    table: {
        style: {
            backgroundColor: 'transparent',
            color: 'var(--text)'
        }
    },
    headRow: {
        style: {
            backgroundColor: 'var(--dbg)',
            color: 'var(--special)',
            borderRadius: 10
        }
    },
    noData: {
        style: {
            backgroundColor: 'var(--dbg)',
            color: 'var(--text)'
        }
    },
    cells: {
        style: {
            '@media (max-width: 767px)': {
                padding: '0 5px',
                textAlign: 'left'
            }
        }
    },
    headCells: {
        style: {
            '@media (max-width: 767px)': {
                padding: '0 5px',
                textAlign: 'left'
            }
        }
    }
}

const Table = ({ columns, data, title, ...rest }) => {
    return (
        <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles}
            title={title}
            pagination
            paginationComponentOptions={{
                noRowsPerPage: true
            }}
            {...rest}
        />
    )
}

export default Table