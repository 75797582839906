import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import { StyledScrollbar } from './'
import { ReactComponent as IconClose } from '@/assets/close_modal.svg'

Modal.setAppElement('#root')

const ModalWrap = styled.div`
  width: 100%;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  overflow-y: scroll;
  ${StyledScrollbar};
  & > svg {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }
  @media (max-width: 767px) {
    padding: 20px;
  }
`



const ModalComponent = ({ open, setOpen, children }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const customStyles = {
    overlay: {
      zIndex: 999,
      backdropFilter: 'blur(2px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.5s',
      background: 'rgba(0, 27, 38, 0.6)',
      opacity: !modalOpen ? '0' : '100%',
    },
    content: {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      background: 'var(--subbg)',
      border: 'none',
      borderRadius: 10,
      padding: 30,
      maxHeight: '100vh',
      overflowY: 'auto',
      transition: 'all 0.5s',
      transform: modalOpen ? 'scale(1)' : 'scale(0)',
    },
  }

  useEffect(() => {
    const body = document.querySelector('body')
    body.style.overflow = open ? 'hidden' : 'auto'
    if (open) {
      setModalOpen(open)
    }
  }, [open])

  const handleClose = (bool) => {
    setModalOpen(false)
    setTimeout(() => {
      setOpen(bool)
    }, 500)
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      style={customStyles}
    >
      <ModalWrap>
        {children}
        <IconClose onClick={handleClose} />
      </ModalWrap >
    </Modal >
  )
}

export default ModalComponent
