import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { formatAmount } from '@/utils/amount'
import { useStore } from '@/utils/hooks'
import { ReactComponent as ImgCopy } from '../../../assets/copy.svg'
import { shortenAddress } from '../../../utils/wallet'
import { observer } from 'mobx-react-lite'

const Balance = () => {
    const store = useStore()
    const { t } = useTranslation()

    const [balance, setBalance] = useState(null)

    useEffect(() => {
        if ('bnb' in store?.wallet?.assets) {
            setBalance(store.wallet.assets.bnb)
        } else {
            setBalance(0)
        }
    }, [store.wallet])

    const copy = () => {
        navigator.clipboard.writeText(store.user.wallet_address)
    }

    return (
        <>
            <h3>{t('Your balance')}</h3>
            <Content>
                <strong>{formatAmount(balance)}</strong>
                BNB
            </Content>
            <WalletAddress onClick={copy}>
                {shortenAddress(store.wallet.walletAddress)}
                <ImgCopy />
            </WalletAddress>
        </>
    )
}

const Content = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    
    strong {
        font-weight: 500;
        font-size: 40px;
        margin-right: 10px;
    }
`

const WalletAddress = styled.div`
    font-size: 14px;
    opacity: 0.5;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 20px;
    
    &:hover {
        opacity: 1;
    }
    
    svg {
        margin-left: 5px;
    }
`

export default observer(Balance)
