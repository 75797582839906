import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Decimal from 'decimal.js-light'
import { formatAmount } from '@/utils/amount'
import Input from '@/components/Input'

const Amount = ({ setAmount, amount, assets, selectedAsset, fee, setError }) => {
    const { t } = useTranslation()

    const setAll = () => {
        const totalAmount = new Decimal(assets[selectedAsset])
        if (selectedAsset === 'bnb') {
            const allAmount = totalAmount.minus(fee)

            if (allAmount <= 0) {
                setAmount('')
                setError('Insufficient funds for cover gas fee')
            } else {
                setAmount(allAmount)
            }
        } else {
            setAmount(formatAmount(totalAmount))
        }
    }

    const onBlur = () => {
        if (amount !== '') {
            setAmount(formatAmount(amount).toString())
        }
    }

    return (
        <Input
            label="Amount"
            onBlur={onBlur}
            onChange={setAmount}
            value={amount}
            type="number"
            placeholder={'0.00'}
            variant="boxed"
            button={Number(formatAmount(assets[selectedAsset])) > 0 &&
                <AllButton onClick={setAll}>{t('All')}</AllButton>
            }
        />
    )

}

const AllButton = styled.div`
    cursor: pointer;
    padding: 5px;
    color: #35A6B6;
`

export default Amount
