import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import Button from '@/components/Button'
import ReactTooltip from 'react-tooltip'
import { Price } from './Price'
import styled from 'styled-components'

const ItemFooter = ({ item, type, handleBuy }) => {
    const { t } = useTranslation()
    return (
        <StyledFooter>
            <Price item={item} showAll={type === 'water' || type === 'socks'} />
            <div>
                <Button onClick={() => handleBuy(item, type)} variant="primary">{t('Buy')}</Button>
                <ReactTooltip />
            </div>
        </StyledFooter>
    )
}

const StyledFooter = styled.div`
    position: relative;
    padding: 10px;
    background-color: #19323C;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
        padding: 5px;
    }
    & > div:first-child {
        font-weight: 600;
        background-color: #FFFFFF1A;
        border-radius: 10px;
        padding: 10px;
        padding-right: 15px;
        display: flex;
        align-items: center;
        img {
            margin-right: 5px;
            height: 20px;
            width: 20px;
            border-radius: 50%;
        }
    }
    
    & > div:last-child {
        flex-basis: 50%;
    }
    
    button {
        width: 100%
    }

    @media (max-width: 767px) {
        & > div:first-child {
            padding: 5px 7px;
        }
        button {
            padding: 5px 7px;
            font-size: 12px;
        }
    }
`

export default observer(ItemFooter)
