import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { ReactComponent as ImgBox } from '../../assets/box.svg'

const Empty = ({ type }) => {
    const { t } = useTranslation()
    return <Content>
        <ImgBox />
        <p>
            {t('Your')} {type} {t('inventory currently is empty')}.<br />
            {t('Shop items here')}
        </p>
        <Link to='/marketplace' onClick={() => window.scrollTo(0, 0)}>
            <Button variant={'primary'}>{t('Marketplace')}</Button>
        </Link>
    </Content>
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 80px 0;
    
    p {
        margin: 40px 0 40px;
    }
`

export default Empty
