import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { get } from '@api'
import { useStore } from '@hooks'
import { Grid } from '@/components'
import Loading from '@/components/Loading'
import Pagination from '@/components/Pagination'
import SuccessFoxpay from './Buy/Modal/SuccessFoxpay'
import { sorters } from './components/Filters'
import PaymentPendingOverlay from './components/PaymentPendingOverlay'

const Items = ({ type, show, render, sort, filter }) => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const searchParams = Object.fromEntries(urlSearchParams.entries())
    if (searchParams.success) {
        window.history.replaceState({}, '', '/')
    }

    const [isLoading, setIsLoading] = useState(true)
    const [paymentPending, setPaymentPending] = useState(false)
    const [paymentResult, setPaymentResult] = useState('')
    const [successItem, setSuccessItem] = useState({})
    const [items, setItems] = useState([])
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [successModalOpen, setSuccessModalOpen] = useState(false)

    const store = useStore()


    // Get foxpay info
    useEffect(() => {
        if (searchParams.success) {
            (async () => {
                const paymentInfo = await getPaymentInfo()
                if (paymentInfo.item_name) {
                    setSuccessItem(paymentInfo)
                    setSuccessModalOpen(true)
                }
            })()
        }
    }, [])

    useEffect(() => {
        if (show && (filter !== null || sort !== null)) {
            (async () => {
                setIsLoading(true)
                await fetchItems()
                setIsLoading(false)
            })()
        }
    }, [sort, JSON.stringify(filter)])

    useEffect(() => {
        if (show) {
            (async () => {
                setIsLoading(true)
                await fetchItems()
                setIsLoading(false)
            })()
        }
    }, [show])

    const fetchItems = async (page = 1) => {
        setIsLoading(true)
        setPage(page)

        const result = await get({
            path: `market/${type}/${page}`,
            params: buildRequestBody(),
        })
        setItems(result.items)
        setTotalPages(result.total_pages)
        setIsLoading(false)
    }

    const getPaymentInfo = async () => {
        setPaymentPending(true)
        try {
            const paymentInfo = await get({
                path: `payment/${searchParams.success}`,
                token: store.tokens.token,
            })
            const pendingStatuses = ['created', 'pending', 'processing']
            if (paymentInfo.code) {
                return
            }
            if (pendingStatuses.includes(paymentInfo.status)) {
                setPaymentResult('processing')
                setTimeout(async () => await getPaymentInfo(), 10000)
                return
            }
            if (paymentInfo.status === 'completed') {
                setPaymentPending(false)
                return paymentInfo
            }
            setPaymentResult(paymentInfo.status)
            setTimeout(() => setPaymentPending(false), 5000)
            return paymentInfo
        } catch (err) {
            console.log('Failed to get payment info: ', err)
        }
    }

    const buildRequestBody = () => {
        let body = {
            sort: {},
        }

        if (sort !== '' && sort !== null) {
            if (type === 'shoes') {
                body.sort = sort
            } else {
                body.sort[Object.keys(sorters[type])[0]] = sort
            }
        }

        if (filter !== null && filter !== {}) {
            body.filters = []

            Object.keys(filter).forEach(key => {
                body.filters[key] = filter[key]
            })
        }

        return body
    }

    const onPageChange = async page => {
        setIsLoading(true)
        window.scrollTo({
            top: document.getElementById('marketplace').offsetTop - 100,
        })

        setTimeout(async () => {
            await fetchItems(page)
        }, 300)
    }

    return (
        <Loading isLoading={isLoading || !show}>
            <Grid col={5} colXs={2} colXsSize={1024}>
                {show && items?.map((item, index) => <div key={index}>{render(item)}</div>)}
            </Grid>
            {show && <Pagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />}
            {/* show success modal if it has search parameter */}
            {type === 'shoes' && successItem &&
                <SuccessFoxpay setOpen={() => setSuccessModalOpen(false)} item={successItem} open={successModalOpen} ariaHideApp={false} />
            }
            {paymentPending && <PaymentPendingOverlay open={paymentPending} paymentResult={paymentResult} />}
        </Loading>
    )
}

export default observer(Items)
