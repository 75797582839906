import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { SimpleLink } from '.'
import GoogleImg from '../assets/google_play.svg'
import ApkImg from '../assets/apk.svg'
import AppStore from '../assets/app_store.svg'
import { ReactComponent as IconClose } from '@/assets/close_modal.svg'

const ModalStyles = styled.div`
	position: fixed;
	z-index: 10;
	left: 50%;
	top: 50vh;
	transform: translate(-50%, -50%);
	width: 364px;
	height: 400px;
	margin: 0 auto;
	text-align: center;
	background: #35a6b6;
	box-shadow: 0px 20px 40px rgba(0, 7, 10, 0.21);
	border-radius: 20px;
	padding-bottom: 40px;
	visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    & > div:last-of-type {
        display: flex;
        flex-direction: column;
        padding: 30px 40px;
    }
	.x {
		margin: 20px 20px 0 0;
		cursor: pointer;
		path {
			fill: white;
		}
	}
	& > div:first-of-type {
		width: 100%;
		text-align: end;
	}
	img:first-of-type {
		margin-bottom: 10px;
	}
`

const LinksModal = ({ isOpen, setIsOpen, closeModal, children, ...rest }) => {
	const modalRef = useRef()
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				setIsOpen(false)
			}
		}

		if (isOpen) {
			document.addEventListener('mousedown', handleOutsideClick)
		} else {
			document.removeEventListener('mousedown', handleOutsideClick)
		}

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick)
		}
	}, [isOpen, modalRef, setIsOpen])

	return (
		<ModalStyles isOpen={isOpen} ref={modalRef} {...rest}>
			<div
				onClick={() => {
					setIsOpen(!isOpen)
				}}
				padding='10px'>
				<IconClose className='x' />
			</div>
			<h3 style={{ fontSize: '36px' }}>Download the TipTop App</h3>
			<div>
				<SimpleLink href='https://play.google.com/store/apps/details?id=com.tiptop.io'>
					<img src={GoogleImg} alt='TipTop' />
				</SimpleLink>
				<SimpleLink href='https://tiptop.io/app.apk'>
					<img src={ApkImg} alt='TipTop' />
				</SimpleLink>
				<SimpleLink href='https://apps.apple.com/app/tiptop-sneakers/id6444293849'>
					<img src={AppStore} alt='TipTop' />
				</SimpleLink>
			</div>
		</ModalStyles>
	)
}

export default LinksModal
