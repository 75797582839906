import styled, { css } from 'styled-components'

const Sort = ({values, onSelect, selected}) => {
    const sortKey = Object.keys(values)[0]

    return <List>
        {Object.keys(values[sortKey]).map((key) =>
            <Item active={key === selected} onClick={() => onSelect(key)} key={key}>{values[sortKey][key]}</Item>
        )}
    </List>
}

export default Sort

const List = styled.div`
    display: flex;
    flex-direction: column;
`

const Item = styled.div`
    padding: 10px 15px;
    cursor: pointer;
    white-space: nowrap;
    
    :first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    
    :last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    
    &:hover {
        background: #233a46;
    }

    ${props => props.active &&
    css`
        background: #011C26;
        color: #35A6B6;
        &:hover {
            background: #011C26;
        }
    `};
`
