import React, { useEffect, useState } from 'react'
import { useStore } from '@hooks'
import styled, { css } from 'styled-components'
import { Container } from '@/components'
import { t } from '@/utils'
import { observer } from 'mobx-react-lite'
import Tabs from '../../components/Tabs'
import Spending from './Spending'
import Wallet from './Wallet'
import ICO from './ICO'
import Modal from '../../components/Modal'
import WalletModal from './WalletModal'
import Transactions from './Transactions'

const Wallets = () => {
    const store = useStore()

    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (params.currency || params.amount) {
        window.history.replaceState({}, '', '/wallet')
    }

    const [tab, setTab] = useState(params.currency ? 2 : 0)
    const [showWalletModal, setShowWalletModal] = useState(false)

    const tabs = [
        t('spending'),
        t('wallet'),
        // t('Public Sale'),
    ]

    useEffect(() => {
        if (tab === 1) {
            (async () => {
                await store.getWallet()
            })()
        }
        if (tab === 0) {
            (async () => {
                await store.getInventory()
            })()
        }
    }, [tab])

    const handleTabClick = (tab) => {
        if (tab === 1 && (store.user.wallet_address === '' || store.user.wallet_address === null)) {
            setShowWalletModal(true)
            return
        }

        setTab(tab)
    }

    return (
        <Wrap>
            <Container>
                <h1>{t('Wallet')}</h1>
                <Tabs items={tabs} active={tab} setActive={handleTabClick} />

                {tab === 0 && <Spending showWalletModal={() => setShowWalletModal(true)} />}
                {tab === 1 && <Wallet />}
                {tab === 2 && <ICO searchParams={params} />}
                {tab !== 2 && <Transactions />}
            </Container>
            <Modal open={showWalletModal} setOpen={() => setShowWalletModal(false)} ariaHideApp={false}>
                <WalletModal
                    onSuccess={() => {
                        setTab(1)
                        setShowWalletModal(false)
                    }}
                />
            </Modal>
        </Wrap>
    )
}

const Wrap = styled.section`
    padding: 200px 0;
    text-align: center;
    
    h1 {
        margin-bottom: 30px;
    }
    @media (max-width: 767px) {
        padding: 80px 0 40px;
    }
`

export const Layout = styled.div`
    display: flex;
    margin: 0 auto;
    max-width: 1320px;
    margin-top: 40px;
    font-size: 16px;
`

export const Left = styled.div`
    flex: 1;
    padding-right: 20px;
    
    @media (max-width: 800px) {
        padding-right: 0;
    }
`

export const Right = styled.div`
    flex: 2;
    h3 {
        margin-bottom: 20px;   
    }
`

export const ModalWrapper = styled.div`
    width: 300px;
    
    h3 {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 600;
    }
`

export const Block = styled.div`
    background-color: var(--subbg);
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 25px;
    text-align: left;
    
    h3 {
        text-align: left;
        font-size: 16px;
        font-weight: 400;
    }
`

export const ActionsContent = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 20px;
    
    & > div {
        cursor: pointer;
        width: 48%;
        background-color: #35A6B6;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 0;
        transition: background-color 0.2s ease;
        
        &:hover {
            background-color: #2e91a0;
        }
        
        svg {
            margin-bottom: 5px;
        }
        
        @media (max-width: 800px) {
            flex-direction: row;
            justify-content: center;
            
            svg {
                margin-right: 8px;
            }
        }
    }
    
    ${props => props.foreverAlone &&
        css`
        & > div {
            width: 100%
        }
    `};
`

export default observer(Wallets)
