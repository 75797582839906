import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from '@/components/Button'
import { useState } from 'react'

const Filter = ({ values, setFilter, filter }) => {
    const [tempFilter, setTempFilter] = useState(filter === null ? {} : filter)

    const { t } = useTranslation()

    const onSelect = (type, value) => {
        setTempFilter({
            ...tempFilter,
            [type]: value === tempFilter[type] ? '' : value,
        })
    }

    const onApply = () => {
        setFilter(tempFilter)
    }

    const onClear = () => {
        setFilter({})
    }

    return <Wrap>
        {Object.keys(values).map(key =>
            <Collection key={key}>
                {key}:
                <Items>
                    {Object.keys(values[key]).map((value) =>
                        <Item active={value === tempFilter[key]} onClick={() => onSelect(key, value)} key={value}>{values[key][value]}</Item>
                    )}
                </Items>
            </Collection>
        )}
        <Footer>
            <Button onClick={onClear} variant={'secondary'}>{t('Clear')}</Button>
            <Button onClick={onApply} variant={'primary'}>{t('Apply')}</Button>
        </Footer>
    </Wrap>
}

export default Filter

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    @media (max-width: 767px) {
        width: 280px;
    }
`

const Collection = styled.div`
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
`

const Items = styled.div`
    display: flex;
    margin-top: 5px;
    
    @media (max-width: 767px) {
        white-space: wrap;
        flex-wrap: wrap;
    }
`

const Item = styled.div`
    background-color: #3B5159;
    border: 1px solid #3B5159;
    border-radius: 30px;
    padding: 6px 12px;
    font-size: 12px;
    margin: 0 10px 10px 0;
    cursor: pointer;
    
    &:hover {
        border-color: #35A6B6;
    }
    
    ${props => props.active &&
        css`
        border-color: #35A6B6;
        color: #35A6B6;
    `};
`

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    
    button {
        width: 47%;
    }
`
